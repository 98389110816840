import React, { useState , useEffect } from 'react'

import './AccountModal.scss'

import Input from '../../settingg/Input/Input'
import Btn from '../../settingg/BTN/Btn'
import { Col, Row } from 'react-bootstrap'
import { AiFillCloseCircle, AiOutlineUpload } from "react-icons/ai"
import { toast, ToastContainer } from "react-toastify"
import useEditInput from '../../../hooks/useEditInput'



const AccountModal = (props) => {
    const [ token , setToken ] = useState("")
    const cssStyle = ["ACCOUNTMODAL", props.show ? "ModalOpen" : "ModalClose"]
    useEffect(() => {
        const getToken = JSON.parse(localStorage.getItem('token'));
        if (getToken) {
            setToken(getToken)
        }
    }, [])


    const name = useEditInput(props.item.name)
    const cardNumber = useEditInput(props.item.cardNumber)
    const shebaNumber = useEditInput(props.item.shebaNumber)
    const hesabNumber = useEditInput(props.item.hesabNumber)
    const bankName = useEditInput(props.item.bankName)

    const editAccount = () => {
        fetch("http://api.pishroopardakht.com/api/account/editAccount", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify({
                id: props.item._id,
                name: name.value,
                cardNumber : cardNumber.value,
                hesabNumber : hesabNumber.value,
                shebaNumber : shebaNumber.value,
                bankName : bankName.value


            })
        }).
            then(async (res) => {
                if (res.status < 300) {
                    res = await res.json();
                    toast.success(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    props.closed()

                } else {
                    res = await res.json();
                    toast.error(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(err => console.log(err))
    }

   

    return (
        <div className={cssStyle.join(" ")}>
            <button className='fw-700 fs-15' onClick={props.closed} style={{ position: "absolute", top: "10px", right: "10px", border: "none", background: " none" }} > <AiFillCloseCircle /> </button>
            <Row className='d-flex justify-content-start align-items-center my-4'>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={name.value} onChange={name.onChange} title="نام" />
                </Col>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={bankName.value} onChange={bankName.onChange} title="نام بانک" />
                </Col>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={cardNumber.value} onChange={cardNumber.onChange} title="شماره کارت" />
                </Col>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={shebaNumber.value} onChange={shebaNumber.onChange} title="شبا" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={hesabNumber.value} onChange={hesabNumber.onChange} title="شماره حساب" />
                </Col>


                <Col xs={12} md={12} sm={12} lg={12} xl={12} >
                    <Btn onClick={editAccount} title="ذخیره" />
                </Col>
            </Row>
        </div>
    )
}

export default AccountModal