import React from 'react'

import {AiFillCloseCircle} from "react-icons/ai"
import './ModalPic.scss'
function ModalPic(props) {
    const cssStyle = ["ModalPic", props.show ? "ModalOpen" : "ModalClose"]

    return (
        <div className={cssStyle.join(" ")}>
            <button className='fw-700 fs-15' onClick={props.closed} style={{ position: "absolute", top: "7px", right: "7px", border: "none", background: " none" }} > <AiFillCloseCircle /> </button>

            <div className='p-3'>
                <img className='IMGreceipt' src={props.src} alt="receipt" />
            </div>
        </div>
    )
}

export default ModalPic