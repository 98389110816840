import React, { useState, useEffect } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);

    const [pr, setPr] = useState([])

    useEffect(() => {
        const getUser = JSON.parse(localStorage.getItem("user"))
        setPr([...getUser.priviliages])
        
    }, [])

    const showSubnav = () => setSubnav(!subnav);
    const location = useLocation()
    return (
        <div className='position-relative w-100'>

            {item.subNav &&
                pr[item.pr] &&
                <div style={{ textDecoration: "none", cursor: "pointer" }} className='d-flex justify-content-between align-items-center w-100' onClick={item.subNav && showSubnav}>
                    <div>
                        <li className={location.pathname == item.path ? "sideLIActive fs-14 fw-900 my-2 font-fa" : "sideLi fs-14 fw-900 my-2 font-fa"} >{item.title}</li>
                    </div>
                    <div style={{ color: "#ffffff", position: "absolute", left: "0px" }}>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                                ? item.iconClosed
                                : null}
                    </div>
                </div>
            }
            {/* {item.subNav &&
                <div style={{ textDecoration: "none",cursor : "pointer" }} className='d-flex justify-content-between align-items-center w-100' onClick={item.subNav && showSubnav}>
                    <div>
                        <li className={location.pathname == item.path ? "sideLIActive fs-14 fw-900 my-2 font-fa" : "sideLi fs-14 fw-900 my-2 font-fa"} >{item.title}</li>
                    </div>
                    <div style={{ color: "#ffffff", position: "absolute", left: "-50px" }}>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                                ? item.iconClosed
                                : null}
                    </div>
                </div>
                } */}

            {!item.subNav &&
                pr[item.pr] &&
                <Link style={{ textDecoration: "none", cursor: "pointer" }} className='d-flex justify-content-between align-items-center w-100' to={item.path} onClick={item.subNav && showSubnav}>
                    <div>
                        <li className={location.pathname == item.path ? "sideLIActive fs-14 fw-900 my-2 font-fa" : "sideLi fs-14 fw-900 my-2 font-fa"} >{item.title}</li>
                    </div>
                    <div style={{ color: "#ffffff", position: "absolute", left: "-50px" }}>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                                ? item.iconClosed
                                : null}
                    </div>
                </Link>
            }

            {subnav &&
                item.subNav.map((i, index) => {
                    return (

                        <NavLink style={{ textDecoration: "none", backgroundColor: "#565658" }} className="decoration-none mx-4" key={index} to={i.path} >
                            <li className={location.pathname == i.path ? "sideLIActive fs-14 fw-900 mx-4 font-fa" : "sideLi fs-14 fw-900 mx-4 font-fa"} >{i.title}</li>
                        </NavLink>
                    );
                })}
        </div>
    );
};

export default SubMenu;