import React, { useEffect, useState } from 'react'


import { RingLoader } from "react-spinners";
import { css } from "@emotion/react";
import './PicSpinner.scss'



const EditBlogModal = (props) => {
    const cssClass = ["PICSPINNER", props.show ? "ModalOpen" : "ModalClose"]
    const override = css`

    margin : 20px 0px;

    `;

    return (
        <div className={cssClass.join(" ")}>
            <div className='w-100 d-flex justify-content-center align-items-center'>
                <RingLoader color="#F0B31D" loading={props.show} css={override} size={25} />
            </div>
            <p className='fw-600 fs-14 font-fa'>{props.title}</p>
        </div>
    )
}

export default EditBlogModal