import React from 'react'

import './Crypto.scss'

function Crypto() {
  return (
    <div>Crypto</div>
  )
}

export default Crypto