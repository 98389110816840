
import './index.scss'

const AdminBtn = ({
    title,
    checked,
    setChecked = () => {}
     
}) => {
    return (
        <div className="CHECKBOX">
            <button onClick={()=>{setChecked(!checked)}} type="button" className={checked ? "activeBTN font-fa text-white" : "inactiveBTN font-fa " }>{title}</button>
        </div>
    )
}

export default AdminBtn