import React from 'react'

import './Btn.scss'

const Btn = ({
  title,
  onClick,
  disabled,
  className
}) => {
  return (
    <button onClick={onClick} disabled={disabled} className={`BtnSetting font-fa text-white fw-700 fs-14 ${className}`}>
        {title}
    </button>
  )
}

export default Btn