import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom';
import { Row, Col } from "react-bootstrap"
import Btn from '../../components/settingg/BTN/Btn'
import Input from '../../components/settingg/Input/Input'
import useInput from '../../hooks/useInput'
import { useDispatch } from 'react-redux'
import useSetToken from '../../hooks/useSetToken'
import axios from '../../boot/axios'
import Toast from '../../methods/_toast';
import useSetUser from '../../hooks/useSetUser'


import "./Login.scss"
const Login = () => {
    const [token, setToken] = useState("");
    // const [user, setUser] = useState({})
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const setTokenn = useSetToken()
    const setUser = useSetUser()


    let formSub = true

    // const loginHandler = async (e) => {
    //     e.preventDefault()


    //     try {
    //         const resAPI = await fetch("http://api.pishroopardakht.com/api/user/login", {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },

    //             body: JSON.stringify({
    //                 phone,
    //                 password: pass

    //             })

    //         })


    //         if (resAPI.status < 300) {
    //             const res = await resAPI.json();


    //             setTokenn(res.token)
    //             setToken(res.token);
                
    //             Toast({text : "Login successfully"})

    //             setUser(res.user)
    //             if (res.isAdmin) {
    //                 setTimeout(()=>{
    //                     navigate('/admin/Purchases-made')
    //                 } , 2000)

    //             }

    //         } else {
    //             const res = await resAPI.json();
    //             console.log(res)

                

    //         }

    //     } catch (error) {

    //     }

    //     resetpassInput()
    //     resetphoneInput()
    // }

    const loginHandler = (e) => {
        e.preventDefault()
        const url = "/user/login"
        const body = { phone, password: pass }
        axios.post(url, body).then((res) => {
            console.log(res)

            if (res.data.isAdmin) {
                const token = res.data.token;
                setToken(token);
                setUser(res.data.user)
                setTokenn(token)
                // setToken(res.data.token)
                setTimeout(() => {
                    navigate('/admin/Purchases-made')
                }, 2000)

            }
        });
    };



    const {
        value: phone,
        isValid: enterphoneIsValid,
        hasError: phoneInputHasError,
        valueChangeHandler: handlephoneChange,
        inputBlurHandler: handlephoneBlur,
        reset: resetphoneInput
    } = useInput(value => value.trim() !== "")


    const {
        value: pass,
        isValid: enterpassIsValid,
        hasError: passInputHasError,
        valueChangeHandler: handlepassChange,
        inputBlurHandler: handlepassBlur,
        reset: resetpassInput
    } = useInput(value => value.trim() !== "")

    if (pass && phone) {
        formSub = false
    }

    return (
        <div className='w-100 LOGIN d-flex justify-content-center align-items-center'>

            <Row className='w-100 d-flex justify-content-center'>
                <Col xs={12} sm={12} md={8} lg={3} xl={3}>
                    <div className='bg-white login-form p-4 w-100 '>
                        <form onSubmit={loginHandler}>
                            <div className='my-2'>
                                <Input type="tel" onChange={handlephoneChange} value={phone} title="شماره تلفن : " />
                            </div>
                            <div className='my-2'>
                                <Input type="password" value={pass} onChange={handlepassChange} title=" رمز عبور : " />
                            </div>
                            <div className='d-flex justify-content-center align-items-center my-4'>
                                <Btn disabled={formSub} type="button" title="ورود" />
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>
    )

}

export default Login