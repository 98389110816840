import React, { useState , useEffect } from 'react'

import { Row, Col } from 'react-bootstrap'

import Btn from '../../../../components/settingg/BTN/Btn'
import useInput from '../../../../hooks/useInput'
import { toast, ToastContainer } from "react-toastify"
import './AddCrypto.scss'
import Input from '../../../../components/Input'
import Form from '../../../../components/Form'
import axios from '../../../../boot/axios'
import Toast from '../../../../methods/_toast'

function AddCrypto() {

  const [ data , setData ] = useState({})

  const formControls = [
    
      {
        label : "نام ارز",
        state : "name"
      },
      {
        label : "نام فارسی ارز",
        state : "persianName"
      },
      {
        label : "نماد",
        state : "symbol"
      },
  ]

  const addCrypto = () => {
    const url = "coin/add_coin"
    axios.post(url , data).then(({data})=>{
      Toast({text : data.message})
    })
  }


  return (
    <div className='w-100 ADD-CRYPTO '>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Row className='w-100 d-flex justify-content-center align-items-center'>
        <Col className="my-4" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-2 addCrypto-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>اضافه کردن رمز ارز</p>
            </div>
            <Row className="mx-1 my-5">
              {formControls.map((e) => (
                                <Col
                                    key={e.state}
                                    xs="12"
                                    md={6}
                                    lg={6}
                                >
                                    {React.createElement(e.tag ?? Input, {
                                        ...e,
                                        value: data[e.state],
                                        setValue: (val) => setData((p) => ({ ...p, [e.state]: val.toLowerCase() })),
                                    })}
                                </Col>
                ))}

              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn disabled={!data.symbol && !data.persianName && !data.name} onClick={addCrypto} title="ثبت اطلاعات رمز ارز" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AddCrypto