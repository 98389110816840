import React, { useEffect, useState } from 'react'

import { Row, Col, Button } from 'react-bootstrap'
import './EditBlogModal.scss'
import { AiFillCloseCircle, AiOutlineUpload } from "react-icons/ai"
import { toast, ToastContainer } from "react-toastify"
import useEditInput from '../../../hooks/useEditInput'
import Input from '../../settingg/Input/Input'
import Btn from '../../settingg/BTN/Btn'
import {axios} from '../../../boot';
import Toast from '../../../methods/_toast';


const EditBlogModal = (props) => {
    const cssClass = ["EditBlogModal", props.show ? "ModalOpen" : "ModalClose"]
    const [image, setImage] = useState(null)
    const [address, setAddress] = useState("")

    const title = useEditInput(props.item.title)
    const content = useEditInput(props.item.content)

    let data = new FormData();

    const editBlog = () => {
        const url = "blog/editPost"
        const body = {
            id: props.item._id,
            title: title.value,
            content: content.value,
            image: address
        }
        axios.put(url , body).then(({data})=>{
            Toast({text : data.message})
        })
    }


    const uploadImageHandler = () => {
        
        console.log("data for image : " , data)
        data.append("image", image);
        const url = "blog/uploadpostimage"
        axios.post(url , data).then(({data})=>{
            setAddress(data.address)
            Toast({text : data.message})
        })

    }




    return (
        <div className={cssClass.join(" ")}>
            
            <button className='fw-700 fs-15' onClick={props.closed} style={{ position: "absolute", top: "5px", right: "10px", border: "none", background: " none" }} > <AiFillCloseCircle /> </button>
            <Row className='d-flex justify-content-start align-items-center '>
                <Col style={{ marginTop: "10px" }} xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input className='' value={title.value} onChange={title.onChange} title="title" />
                </Col>
                <Col style={{ marginTop: "10px" }} xs={12} sm={12} md={12} lg={6} xl={6} className="d-flex flex-column justify-content-end" >
                    <p className='fs-14 text-end font-fa fw-700 mx-2'>عکس : </p>
                    <div className="d-flex justify-content-center align-items-end">
                        <input onChange={(e) => setImage(e.target.files[0])} type="file" id="uploadImage" for="uploadImage" hidden />
                        <label style={{ padding: "9px", borderRadius: "8px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", minWidth: "100px " }} className='w-75  bg-yellow  text-white text-center font-fa fw-700 fs-12 cursor-pointer my-2' for="uploadImage"> انتخاب عکس </label>
                        <button onClick={uploadImageHandler} style={{ padding: "8px", borderRadius: "8px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", minWidth: "70px" }} className='w-25  bg-light  text-yellow border-0 text-center font-fa fw-700 fs-12 cursor-pointer my-2'>آپلود <AiOutlineUpload /></button>
                    </div>
                </Col>
                <Col xs={12} md={12} sm={12} lg={12} xl={12} className="d-flex flex-column justify-content-end align-items-start"  >
                    <p className='fs-14 font-fa fw-700 mx-2'>توضيحات : </p>
                    <textarea className='w-100' value={content.value} onChange={content.onChange} rows="5" cols="50"></textarea>
                </Col>

                {/* <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={address.value} onChange={address.onChange} title="Address" />
                </Col> */}

                <Col xs={12} md={12} sm={12} lg={12} xl={12} >
                    <Btn onClick={editBlog} title="ذخیره" />
                </Col>
            </Row>
        </div>
    )
}

export default EditBlogModal