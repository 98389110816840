import React, { useState, useEffect } from 'react'

import './AddMessage.scss'
import { Row, Col } from "react-bootstrap"

import Btn from '../../../components/settingg/BTN/Btn'
import useInput from '../../../hooks/useInput'
import { toast, ToastContainer } from "react-toastify"
import { AiOutlineUpload } from 'react-icons/ai'
import { axios } from '../../../boot'
import Input from '../../../components/Input'
import Toast from '../../../methods/_toast'

function AddMessage() {
  const [data, setData] = useState({})

  //--------------------------message-----------------------------
  // const {
  //   value: message,
  //   isValid: entermessageIsValid,
  //   hasError: messInputHasError,
  //   valueChangeHandler: handlemessageChange,
  //   inputBlurHandler: handlemessageBlur,
  //   reset: resetmessage
  // } = useInput(value => value.trim() !== "")


  // const {
  //   value: messageBody,
  //   isValid: entermessageBodyIsValid,
  //   hasError: messageBodyInputHasError,
  //   valueChangeHandler: handlemessBodyChange,
  //   inputBlurHandler: handlemessageBodyBlur,
  //   reset: resetmessageBody
  // } = useInput(value => value.trim() !== "")

  const addMessage = () => {
    const url = "message/addMessage"
    
    axios.post(url, data).then(({ data }) => {
      console.log(data)
      Toast({text : data.message , type : data.status < 300 ? "success" : "error"})
      setData({})
    })
  }

  const formControls = [
    {
      label : "title",
      state : "title"
    },
    {
      as: "textarea",
      label: "توضیحات",
      state: "content",
    },
  ]

  


  

  //-------------------message--------------------------




  return (
    <div className='w-100 ADD-MESSAGE d-flex justify-content-center '>
      <Row className='w-100 d-flex justify-content-center align-items-center'>
        <Col className="my-4" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-2 addMessage-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>ارسال پیام</p>
            </div>
            <Row className="mx-1 my-5">
              {formControls.map((e) => (
                <Col
                  key={e.state}
                  xs="12"
                  md={12}
                  lg={12}
                  className=""
                >
                  {React.createElement(e.tag ?? Input, {
                    ...e,
                    value: data[e.state],
                    setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                  })}
                </Col>
              ))}
              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn disabled={!data.content || !data.title} onClick={addMessage} title="ثبت پیام جدید " />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AddMessage