import React from 'react'

const Pagi = ({ activePage, setActivePag = () => { } , totalPage }) => {
  
  return (
    <div dir='ltr' className='d-flex flex-column align-items-center flex-md-row justify-content-md-around gap-3 mx-4'>
      <div style={{ color: "#606F89" }} className=' fs-15 fw-500' >{`${activePage} / ${totalPage}`}</div>
      <div className='font-fa d-flex justify-content-center align-items-center gap-4 '>
        <button style={{ color: "#606F89" }} className='bg-white text-tableTd border-0 fs-15 fw-500' onClick={() => { setActivePag(activePage - 1) }} disabled={activePage === 1}> {"<"} </button>
        <button style={{ color: "#606F89" }} className='bg-white text-tableTd border-0 fs-15 fw-500' onClick={() => { setActivePag(activePage + 1) }} disabled={activePage === totalPage}> {">"} </button>
      </div>
    </div>
  )
}

export default Pagi