import React, { useState, useMemo } from 'react'

import { DropdownButton, Dropdown } from 'react-bootstrap'
import Pagination from '../../components/Pagination/Pagination'
import Search from '../../components/Search/Search'
import ItemSentModal from '../../components/modal/ItemSentModal/ItemSentModal'
import './ItemsSent.scss'
import BackDrop from '../../components/BackDrop/BackDrop'
const ItemsSent = () => {

    const [PageSize, setPagesize] = useState(8)
    const [currentPage, setCurrentPage] = useState(1);
    const [ show , setShow ] = useState(false)

    const openModal = () => {
        setShow(true)
    }

    const closeModal = () => {
        setShow(false)
    }

    const orderlist = [
        { number: 1, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 2, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 3, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 4, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 5, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 6, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 7, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 8, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 9, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 10, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 11, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 12, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 13, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 14, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 15, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 16, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 17, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 18, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 19, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 20, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 21, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },
        { number: 22, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: false, ehraz2: "در انتظار تاييد" },
        { number: 23, name: "محمد حسين زاده", ehraz: "فروش 300 Tether", explain: "300,340,00", date: "37589633021", status: true, ehraz2: "در انتظار تاييد" },

    ]

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return orderlist.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);
    return (
        <div>
            <div className="ITEMSSENT position-relative rounded-10 shadow bg-white m-1">
                {show && <ItemSentModal show={show} closed={closeModal} />}
                {show && <BackDrop show={show} />}
                <div className="my-3 mx-4 d-flex flex-column align-items-center flex-md-row justify-content-md-end align-items-md-center">

                    <div className="mx-2  d-flex  align-items-center">
                        <span className='cursor-pointer mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z" fill="#F0B31D" />
                                <path d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z" fill="#F0B31D" />
                                <path d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z" fill="#F0B31D" />
                            </svg>
                        </span>
                        <Search placeholder='جستجو كنيد ...' />
                    </div>
                </div>

                <div className="w-100 p-1 my-1">
                    <div className="orderList">
                        <table className="w-100 ">
                            <thead className='bg-white'>
                                <tr className='font-fa bg-white'>
                                    <th className='mx-2'>#</th>
                                    <th>نام و نام خانوادگي</th>
                                    <th>عكس ارسالي</th>
                                    <th>احراز براي</th>
                                    <th>توضيحات</th>
                                    <th>زمان سفارش</th>
                                    <th>وضعيت</th>
                                    <th>احراز هويت</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {currentTableData.map((i) =>
                                    <tr className='font-fa'>
                                        <td className="fs-12 text-tableTd">{i.number}</td>
                                        <td className="fs-12 text-tableTd">{i.name}</td>
                                        <td className=''>
                                            <img src="" alt="receipt" width="60px" height="60px" />
                                        </td>
                                        <td className="fs-12 text-tableTd">{i.ehraz}</td>
                                        <td className="fs-12 text-tableTd">{i.explain}</td>
                                        <td className="fs-12 text-tableTd">{i.date}</td>
                                        <td className="fs-12 text-tableTd ">
                                            {i.status && <div className='acc rounded-pill font-fa fs-12 fw-900 px-2 py-1 text-center '>تاييد شده</div>}
                                            {!i.status && <div className='rej rounded-pill font-fa fs-12 fw-900 px-2 py-1 text-center'>رد شده</div>}
                                        </td>
                                        <td className="fs-12 text-tableTd">{i.ehraz2}</td>
                                        <td className='d-flex justify-content-center align-items-center'>
                                            <span className='cursor-pointer'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                                                    <path d="M15.1872 1.47368H10.9091V1.43158C10.9091 0.631579 10.2674 0 9.45455 0H6.54545C5.73262 0 5.09091 0.631579 5.09091 1.43158V1.47368H0.812834C0.363636 1.47368 0 1.83158 0 2.27368V3.07368C0 3.51579 0.363636 3.87368 0.812834 3.87368H15.1872C15.6364 3.87368 16 3.51579 16 3.07368V2.27368C16 1.83158 15.6364 1.47368 15.1872 1.47368Z" fill="#A82070" />
                                                    <path d="M14.1177 4.96851H1.88244C1.64714 4.96851 1.47602 5.15798 1.49741 5.38956L2.09634 17.579C2.16051 18.9264 3.2942 20.0001 4.66319 20.0001H11.3156C12.6846 20.0001 13.8183 18.9475 13.8824 17.579L14.4814 5.38956C14.5241 5.17903 14.3316 4.96851 14.1177 4.96851ZM6.67388 16.7369C6.67388 17.1159 6.37442 17.4106 5.98939 17.4106C5.60436 17.4106 5.3049 17.1159 5.3049 16.7369V8.25272C5.3049 7.87377 5.60436 7.57903 5.98939 7.57903C6.37442 7.57903 6.67388 7.87377 6.67388 8.25272V16.7369ZM10.6953 16.7369C10.6953 17.1159 10.3958 17.4106 10.0108 17.4106C9.62575 17.4106 9.32629 17.1159 9.32629 16.7369V8.25272C9.32629 7.87377 9.62575 7.57903 10.0108 7.57903C10.3958 7.57903 10.6953 7.87377 10.6953 8.25272V16.7369Z" fill="#A82070" />
                                                </svg>
                                            </span>
                                            <span className='my-3 mx-3 cursor-pointer' onClick={openModal}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                    <path d="M1.5034 9.31636L0 13.9999L4.68367 12.4735L13.9354 3.26091L10.764 0.0900879L1.5034 9.31636Z" fill="#F0B31D" />
                                                </svg>
                                            </span>

                                        </td>

                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            <div className="w-100 my-3  d-flex flex-column align-items-center flex-md-row justify-content-md-between align-items-md-center">

                <div className="d-flex flex-column align-items-center flex-md-row justify-content-md-between align-items-md-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={orderlist.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                    <div className=" d-flex align-items-center  justify-content-between mx-3">
                        <DropdownButton
                            className="mb-2"
                            variant="light"
                            title={PageSize}
                        >
                            <Dropdown.Item eventKey="1" onClick={() => { setPagesize(8) }}>8</Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={() => { setPagesize(9) }}>9</Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={() => { setPagesize(10) }}>10</Dropdown.Item>

                        </DropdownButton>
                        <p className='d-inline-block fw-600 fs-12'>:Rows per page </p>

                    </div>
                </div>
                <div>
                    <p className='d-inline-block mx-1 fw-400 fs-10'>496/706</p>
                    <p className='d-inline-block mx-3 fw-400 fs-10'>:ACTIVE CUSTOMERS </p>
                </div>
            </div>
        </div>
    )
}

export default ItemsSent