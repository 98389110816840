import React, { useEffect, useState } from 'react'

import './Support.scss'
import { Button, Row, Col } from 'react-bootstrap'
import Search from '../../components/Search/Search'
import ModalPic from '../../components/modal/picture/ModalPic'
import BackDrop from '../../components/BackDrop/BackDrop'
import SupModal from '../../components/modal/SupportModal/SupModal'
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import { toast, ToastContainer } from "react-toastify"
import Pagi from '../../components/Pagi/Pagi'
import DeleteModal from '../../components/modal/deleteModal/DeleteModal'
import { useSelector, useDispatch } from 'react-redux'
import axios from '../../boot/axios'
import Toast from '../../methods/_toast'
import Modal from '../../components/NewModal'
import Btn from '../../components/settingg/BTN/Btn'




const Support = () => {

    const [getUser, setGetUser] = useState([])
    const [count, setCount] = useState(0)
    const [show, setShow] = useState(false)
    const [showMess, setShowMess] = useState(false)
    // const [img, setImg] = useState();
    const [userId, setUserId] = useState("")
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()

    const override = css`
        position : absolute;
        top : 10rem;
        left : 45%;
        margin : 20px 0px;

        `;


    const openModal = () => {
        setShow(true)
    }

    const openModal2 = () => {
        setShowMess(true)
    }

    const closeModal2 = () => {

        setShowMess(false)
    }

    const closeModal = () => {
        setShow(false)
    }

    const getAllTicket = () => {
        const url = "support/getAllTickets"
        const body = {
            limit: 10,
            page
        }
        axios.post(url, body).then(({ data }) => {
            setTotal(data.totalPages)
            setGetUser([...data.tickets])
        })
    }


    useEffect(getAllTicket, [page, count])

    const handleTicketStatus = (enterId, status) => {
        const url = "support/statusTicket"
        const body = {
            ticketid: enterId,
            status
        }
        axios.post(url, body).then(({ data }) => {
            Toast({ text: data.message })
            setCount(p => p = p + 1)
        })
        // fetch("https://api.pishroopardakht.com/api/support/statusTicket", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization": token
        //     },
        //     body: JSON.stringify({
        //         ticketid: enterId,
        //         status
        //     })
        // }).
        //     then(async (res) => {

        //         if (res.status < 300) {
        //             res = await res.json();


        //             toast.success(res.message, {
        //                 position: "top-left",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //             });


        //         } else {
        //             res = await res.json();


        //             toast.error(res.message, {
        //                 position: "top-left",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //             });

        //         }
        //     }).catch(err => console.log(err))
    }


    // const handleChat = (enter) => {
    //     fetch("https://api.pishroopardakht.com/api/support/getTicketChats", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         },
    //         body: JSON.stringify({
    //             ticketId: enter
    //         })
    //     }).
    //         then(async (res) => {
    //             console.log(res.status)
    //             if (res.status < 300) {
    //                 res = await res.json();
    //                 console.log(res)
    //                 setChat([...res])


    //             } else {
    //                 res = await res.json();
    //                 console.log(res)

    //             }
    //         }).catch(err => console.log(err))
    // }
    const option = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    }

    const gettime = (date) => {

        let b = date.split("T")[1]
        return b.slice(0, 8)
    }

    // const deleteSup = (enterId) => {
    //     fetch("http://api.pishroopardakht.com/api/Support/deleteTicket", {
    //         method: "DELETE",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         },
    //         body: JSON.stringify({
    //             id: enterId
    //         })
    //     }).
    //         then(async (res) => {
    //             console.log(res.status)
    //             if (res.status < 300) {
    //                 res = await res.json();
    //                 console.log(res)
    //                 toast.success(res.message, {
    //                     position: "top-left",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });

    //             } else {
    //                 res = await res.json();
    //                 console.log(res)
    //                 toast.error(res.message, {
    //                     position: "top-left",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //             }
    //         }).catch(err => console.log(err))


    // }

    const deleteTicket = () => {
        const url = "Support/deleteTicket"
        const body = {
            id: userId
        }
        axios.delete(url, body).then(({ data }) => {

            if (data.status > 399) {
                Toast({ text: data.message, type: "error" })
            } else {
                Toast({ text: data.message })
            }

        })
    }


    return (
        <div>
            <div className="SUPPORT position-relative rounded-10 shadow bg-white m-1">
                
                <Modal
                    show={show}
                    onHide={setShow}
                    size="md"
                    title="آیا از حذف تیکت مطمئن هستید؟"
                    type='yellow'
                >
                    <DeleteModal onHide={setShow} url="http://api.pishroopardakht.com/api/Support/deleteTicket"  id={userId} />
                </Modal>

                <Modal
                    show={showMess}
                    onHide={setShowMess}
                    size="xl"
                    type='yellow'
                >
                    <div className="w-100">
                        <SupModal id={userId}  />
                    </div>

                </Modal>
                <div className="my-3 mx-4 d-flex flex-column align-items-center flex-md-row justify-content-md-end align-items-md-center">
                    <ToastContainer
                        position="top-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    {/* <div className="mx-2  d-flex  align-items-center">
                        <span className='cursor-pointer mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z" fill="#F0B31D" />
                                <path d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z" fill="#F0B31D" />
                                <path d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z" fill="#F0B31D" />
                            </svg>
                        </span>
                        <Search placeholder='جستجو كنيد ...' />
                    </div> */}
                </div>

                <div className="w-100 p-1 my-1">

                    <div className="supportList">

                        <table className="w-100 ">
                            <thead className='bg-white'>
                                <tr className='font-fa bg-white'>
                                    <th className='mx-2'>#</th>
                                    <th>نام و نام خانوادگي</th>
                                    <th>موضوع تیکت</th>
                                    <th>بخش مرتبط</th>
                                    <th>آخرین بروزرسانی</th>
                                    <th>وضعیت</th>
                                    <th>بستن تیکت</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>



                                {getUser && getUser.map((i, index) =>


                                    <tr key={i._id} className='font-fa'>
                                        <td className="fs-12 text-tableTd">{index + 1}</td>
                                        <td className="fs-12 text-tableTd">{i.user && i.user['name']}</td>
                                        <td className="fs-12 text-tableTd">{i.title}</td>

                                        <td className="fs-12 text-tableTd">{i.department && i.department.name}</td>
                                        <td className="fs-12 text-tableTd">
                                            <p className="my-1">{new Date(i.updatedAt).toLocaleDateString("fa-IR", option)}</p>
                                            <p className="">{gettime(i.updatedAt)}</p>
                                        </td>
                                        <td className="fs-12 text-tableTd">
                                            {i.status == 0 && <div className='accSup fw-400 fs-12 text-center font-fa py-1'>تیکت جدید</div>}
                                            {i.status == 2 && <div className='rejSup fw-400 fs-12 text-center font-fa py-1'>بسته شد</div>}
                                            {i.status == 1 && <div className='waitSup fw-400 fs-12 text-center font-fa py-1'>در حال پاسخگویی</div>}
                                        </td>
                                        <td className="fs-12 text-tableTd d-flex justify-content-start align-items-center">
                                            <button onClick={() => { handleTicketStatus(i._id, 2); setCount(p => p + 1) }} className='rejAuth font-fa fw-700 fs-14 mx-1 my-2'>بستن</button>
                                            <button onClick={() => { handleTicketStatus(i._id, 1); setCount(p => p + 1) }} className='accAuth font-fa fw-700 fs-14 my-2'>بازکردن</button>
                                        </td>

                                        {/* <td className="fs-12 text-tableTd"><img onClick={() => { openModal(); setImg(`http://api.pishroopardakht.com${i.attachment}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.attachment}`} alt='attampt' width="60" height="60" /></td> */}

                                        <td className="fs-12 text-tableTd">
                                            {i.status == 1 && <button onClick={() => { setUserId(p=>p=i._id); openModal2() }} className='watchSup  text-center py-1 text-yellow font-fa fw-700 fs-14'>مشاهده</button>}
                                            {i.status == 0 && <button onClick={() => { setUserId(p=>p=i._id); openModal2() }} className='answerSup text-center rounded-5 px-2 py-1 text-white font-fa fw-700 fs-14'>پاسخ به تیکت</button>}
                                        </td>
                                        <td className='d-flex justify-content-center align-items-center'>
                                            <span onClick={() => { openModal(); setUserId(p => p = i._id) }} className='cursor-pointer py-2 my-3'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                                                    <path d="M15.1872 1.47368H10.9091V1.43158C10.9091 0.631579 10.2674 0 9.45455 0H6.54545C5.73262 0 5.09091 0.631579 5.09091 1.43158V1.47368H0.812834C0.363636 1.47368 0 1.83158 0 2.27368V3.07368C0 3.51579 0.363636 3.87368 0.812834 3.87368H15.1872C15.6364 3.87368 16 3.51579 16 3.07368V2.27368C16 1.83158 15.6364 1.47368 15.1872 1.47368Z" fill="#A82070" />
                                                    <path d="M14.1177 4.96851H1.88244C1.64714 4.96851 1.47602 5.15798 1.49741 5.38956L2.09634 17.579C2.16051 18.9264 3.2942 20.0001 4.66319 20.0001H11.3156C12.6846 20.0001 13.8183 18.9475 13.8824 17.579L14.4814 5.38956C14.5241 5.17903 14.3316 4.96851 14.1177 4.96851ZM6.67388 16.7369C6.67388 17.1159 6.37442 17.4106 5.98939 17.4106C5.60436 17.4106 5.3049 17.1159 5.3049 16.7369V8.25272C5.3049 7.87377 5.60436 7.57903 5.98939 7.57903C6.37442 7.57903 6.67388 7.87377 6.67388 8.25272V16.7369ZM10.6953 16.7369C10.6953 17.1159 10.3958 17.4106 10.0108 17.4106C9.62575 17.4106 9.32629 17.1159 9.32629 16.7369V8.25272C9.32629 7.87377 9.62575 7.57903 10.0108 7.57903C10.3958 7.57903 10.6953 7.87377 10.6953 8.25272V16.7369Z" fill="#A82070" />
                                                </svg>
                                            </span>

                                        </td>

                                    </tr>


                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            <div className="w-100 my-3  d-flex flex-column align-items-center flex-md-row justify-content-md-around align-items-md-center">
                <Pagi activePage={page} setActivePag={setPage} totalPage={total} />


                <div>
                    <p className='d-inline-block mx-1 fw-400 fs-10'>496/706</p>
                    <p className='d-inline-block mx-3 fw-400 fs-10'>:ACTIVE CUSTOMERS </p>
                </div>
            </div>
        </div>
    )

}

export default Support