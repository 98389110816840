import React , {useState , useEffect} from 'react'

import './DeleteModal.scss'
import { Row , Col } from 'react-bootstrap'
import Btn from '../../settingg/BTN/Btn'
import Button from '../../Button/Button'
import { toast, ToastContainer } from "react-toastify"
import Toast from '../../../methods/_toast'





const DeleteModal = ({
    url = "",
    onHide = () => {},
    id,
    onPage = () => {}

}) => {
    const [ token , setToken ] = useState("")

    useEffect(()=>{
        const getToken = JSON.parse(localStorage.getItem('token'));
        if (getToken) {
            setToken(pre => pre = getToken);
        }
    } , [])

    const deleteUser = () => {
        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify({
                id: id
            })
        }).
            then(async (res) => {

                if (res.status < 300) {
                    res = await res.json();
                    Toast({text : res.message})
                    onHide(false)
                    onPage(1)
                } else {
                    res = await res.json();
                    Toast({text : res.message , type : "error"})
                }
            }).catch(err => console.log(err))
    }
    return (
        <div className="w-100 d-flex justify-content-center align-items-center">
            
            <Row className='d-flex justify-content-around w-100 my-2'>
                <Col className='p-2' xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Btn onClick={()=>{deleteUser();}} className="w-100" title="بله" />
                </Col>
                <Col className='p-2 ' xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Button onClick={()=>onHide(false)} className="bg-white text-dark shadow-sm w-100" title="خیر" />
                </Col>
            </Row>
        </div>
    )
}

export default DeleteModal