import React , {useState} from 'react'

const useEditInput = (initialValue) => {
        const [value, setValue] = useState(initialValue);
        const handleChange = (event) => {
            setValue(event.target.value);
        };
        const resetInput = () =>{
            setValue("");
        }

        return {
            value,
            onChange: handleChange,
            reset: resetInput
        };
}

export default useEditInput