import React, { useState } from 'react'

import './UserOrder.scss'
import { ActiveOrder, AllOrder, RejectOrder } from "./components/index"
import Context from "./_context";



function UserOrder() {
    const [page, setPage] = useState("active");


    return (

        <div className="w-100">
            <div className="USERORDER position-relative rounded-10 shadow bg-white m-1">
                <div className="w-100 p-1 my-1">
                    <Context.Provider value={[page, setPage]}>
                        {page == "all" && <AllOrder />}
                        {page == "active" && <ActiveOrder />}
                        {page == "reject" && <RejectOrder />}
                    </Context.Provider>

                </div>
            </div>
        </div>


    )
}

export default UserOrder