import React, { useState } from 'react'

import './ManageTether.scss'
import { Row, Col } from 'react-bootstrap'
import Alert from '../../components/Alert/Alert'
import Input from '../../components/settingg/Input/Input'
import Btn from '../../components/settingg/BTN/Btn'
import useInput from '../../hooks/useInput'
function ManageTether() {
    const [result, setResult] = useState("")
    const [type, setType] = useState("")
    const [showAlert, setShowAlert] = useState(false)

    const {
        value : buyTether,
        isValid : enterbuyTetherIsValid,
        hasError : buyTetherInputHasError,
        valueChangeHandler :  handlebuyTether,
        inputBlurHandler : handlebuyTetherBlur,
        reset : resetbuyTetherInput
    } = useInput(value => value.toString().length > 0)

    const {
        value : sellTether,
        isValid : entersellTetherIsValid,
        hasError : sellTetherInputHasError,
        valueChangeHandler :  handlesellTether,
        inputBlurHandler : handlesellTetherBlur,
        reset : resetsellTetherInput
    } = useInput(value => value.toString().length > 0)

    const buyTetherHandler = async() => {

        try{
              const resAPI = await fetch("http://api.pishroopardakht.com/api/tether/addbuyprice",{
                  method : "POST",
                  headers : {"Content-Type": "application/json"
              },
                  
                  body: JSON.stringify({
                      price : buyTether

                  })
      
              })
              
              if(resAPI.status < 300){
                const res = await resAPI.json();
                
                
                setResult(res.message)
                setShowAlert(true)
                setType("success")
                setTimeout(()=>{
                    setShowAlert(false)
                    setType("")
                    setResult("")
                },4000)
                resetbuyTetherInput()
              }else{
                const res = await resAPI.json();
                console.log(res)
                setResult(res.message)
                setShowAlert(true)
                setType("error")
                setTimeout(()=>{
                    setShowAlert(false)
                    setType("")
                    setResult("")
                },4000)
              }
          }catch(error){
              
          }
      }




      const sellTetherHandler = async() => {

        try{
              const resAPI = await fetch("http://api.pishroopardakht.com/api/tether/addsellprice",{
                  method : "POST",
                  headers : {"Content-Type": "application/json"
              },
                  
                  body: JSON.stringify({
                      price : sellTether

                  })
      
              })
              console.log(resAPI.status)
              if(resAPI.status < 300){
                const res = await resAPI.json();
                console.log(res)
                
                setResult(res.message)
                setShowAlert(true)
                setType("success")
                setTimeout(()=>{
                    setShowAlert(false)
                    setType("")
                    setResult("")
                },4000)
                resetsellTetherInput()
              }else{
                const res = await resAPI.json();
                console.log(res)
                setResult(res.message)
                setShowAlert(true)
                setType("error")
                setTimeout(()=>{
                    setShowAlert(false)
                    setType("")
                    setResult("")
                },4000)
              }
          }catch(error){
              
          }
      }
    return (
        <div className='MANAGE_TETHER w-100'>
            {showAlert && <Alert result={result} type={type} />}
            <Row className='w-100 d-flex justify-content-center align-items-center'>
                <Col className="my-4" xs={12} sm={12} md={12} lg={6} xl={6} >
                    <div className=' w-100 p-2 addTether-div shadow mx-1 my-1'>
                        <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
                            <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>قیمت خرید تتر</p>
                        </div>
                        <Row className="mx-1 my-5">
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="">
                                <Input title="قیمت خرید"  value={buyTether} onChange={handlebuyTether} />
                            </Col>
                            <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Btn disabled={!buyTether} onClick={buyTetherHandler} title="ثبت قیمت خرید تتر " />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col className="my-4" xs={12} sm={12} md={12} lg={6} xl={6} >
                    <div className=' w-100 p-2 addTether-div shadow mx-1 my-1'>
                        <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
                            <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>قیمت فروش تتر</p>
                        </div>
                        <Row className="mx-1 my-5">
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="" >
                                <Input title="قیمت فروش" value={sellTether} onChange={handlesellTether} />
                            </Col>
                            <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Btn disabled={!sellTether} onClick={sellTetherHandler} title="ثبت قیمت فروش تتر" />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ManageTether