
import React , {useEffect} from "react";
import routes from "./routes";
import {useSelector} from 'react-redux'

import {
  useRoutes , useLocation
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify"
import Loading from "./components/Loading";





function App() {
  const isLogged = useSelector((s) => s.isLogged);
  const elements = useRoutes(routes(isLogged));
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return(
    <React.Fragment>
      <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
      {elements}
      <Loading/>
    </React.Fragment>
  )
}

export default App;
