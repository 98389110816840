import Layout from "../Layout/Layout";
import SellOrder from "../pages/SellOrder/SellOrder";
import ItemsSent from "../pages/ItemsSent/ItemsSent";
import Setting from "../pages/Settingg/Setting";
import UserOrder from "../pages/UserOrder/UserOrder";
import UserList from "../pages/UserList/UserList";
import Support from "../pages/Support/Support";
import Crypto from "../pages/Cryptocurrency/Crypto";
import AddCrypto from "../pages/Cryptocurrency/components/add/AddCrypto";
import EditCrypto from "../pages/Cryptocurrency/components/edit/EditCrypto";
import AddWallet from "../pages/Wallet/AddWallet/AddWallet";
import ManageTether from "../pages/ManageTether/ManageTether";
import RuleManage from "../pages/RuleManagement/RuleManage";
import Login from "../pages/Login/Login";
import Auth from "../pages/Authentication/Auth";
import Chat from "../components/Chat/Chat";
import Main from "../pages/Main/Main";
import EditWallet from "../pages/Wallet/EditWallet/EditWallet";
import AddBlog from "../pages/Blog/Add/AddBlog";
import EditBlog from '../pages/Blog/Edit/EditBlog'
import AddDepartment from "../pages/Department/Add/AddDepartment";
import EditDepartment from '../pages/Department/Edit/EditDepartment'
import IncreaseCredit from "../pages/IncreaseCredit/IncreaseCredit";
import AddAccount from "../pages/Account/Add/AddAccount";
import EditAccount from '../pages/Account/Edit/EditAccount'
import EditMessage from '../pages/Message/edit/EditMessage'
import AddMessage from '../pages/Message/add/AddMessage'
import BankCards from '../pages/BankCards/BankCards'
import Admin from '../pages/Admin'

const routes = (isLogged = false) => [
    {
        path: "/",
        element: <Main />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/admin",
        element: isLogged && <Layout />,
        children: [
          { path: "/admin/Purchases-made", element: <UserOrder /> },
          { path: "/admin/Sales-orders", element: <SellOrder /> },
          { path: "/admin/Items-sent", element: <ItemsSent /> },
          { path: "/admin/Setting", element: <Setting /> },
          { path: "/admin/User-list", element: <UserList /> },
          { path: "/admin/Support", element: <Support /> },
          { path: "/admin/Crypto", element: <Crypto /> },
          { path: "/admin/Crypto/add", element: <AddCrypto /> },
          { path: "/admin/Crypto/edit", element: <EditCrypto /> },
          { path: "/admin/Wallet/add", element: <AddWallet /> },
          { path: "/admin/Wallet/edit", element: <EditWallet /> },
          { path: "/admin/manage-tether", element: <ManageTether /> },
          { path: "/admin/Rule-management", element: <RuleManage /> },
          { path: "/admin/Authentication", element: <Auth /> },
          { path: "/admin/Blog/add", element: <AddBlog /> },
          { path: "/admin/Blog/edit", element: <EditBlog /> },
          { path: "/admin/Department/add", element: <AddDepartment /> },
          { path: "/admin/Department/edit", element: <EditDepartment /> },
          { path: "/admin/Increase-credit", element: <IncreaseCredit /> },
          { path: "/admin/Account/add", element: <AddAccount /> },
          { path: "/admin/Account/edit", element: <EditAccount /> },
          { path: "/admin/Message/add", element: <AddMessage /> },
          { path: "/admin/Message/edit", element: <EditMessage /> },
          { path: "/admin/Bank-cards", element: <BankCards /> },
          { path: "/admin/Add-admin", element: <Admin /> },
          
        ],
      },
]

export default routes;