import React, { useState } from 'react'

import './AddWallet.scss'
import { Row, Col } from "react-bootstrap"
import Btn from '../../../components/settingg/BTN/Btn'
import { AiOutlineUpload } from 'react-icons/ai'
import {axios} from '../../../boot'
import Toast from '../../../methods/_toast';
import Input from '../../../components/Input'

function AddWallet() {
  const [image, setImage] = useState(null)
  const [ data , setData ] = useState({})

  let formsubmit = true
  if (data?.name && data?.address && data?.fee && data?.symbol) {
    formsubmit = false
  }

  let dataa = new FormData();

  const  uploadImageHandler = () => {
    dataa.append("image", image);
    const url = "wallet/uploadqrimage"
    axios.post(url , dataa).then(({data})=>{
        console.log(data)
        setData((p) => ({...p , qrCodeimage : data.address }))
        Toast({text : data.message})
    })
  }

  const submitWallet = async () => {
    const url = "wallet/add_wallet"
    axios.post(url , data).then(({data})=>{
      Toast(data.message)
      setData({})
    })
  }

  const formControls = [
    {
      label : "نام شبکه",
      state : "name"
    },
    {
      label: "Fee" ,
      state: "fee",
    },
    {
      label: "نماد" ,
      state: "symbol",
    },
    {
      label: "آدرس" ,
      state: "address",
    },
    {
      label: "mem" ,
      state: "mem",
    },
  ]

  return (
    <div className='w-100 ADD-WALLET d-flex justify-content-center '>
      <Row className='w-100 d-flex justify-content-center align-items-center'>
        <Col className="my-4" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-2 addWallet-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>اضافه کردن ولت</p>
            </div>
            <Row className="mx-1 my-5">
              {formControls.map((e) => (
                <Col
                  key={e.state}
                  xs="12"
                  md={6}
                  lg={6}
                  className=""
                >
                  {React.createElement(e.tag ?? Input, {
                    ...e,
                    value: data[e.state],
                    setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                  })}
                </Col>
              ))}

              <Col xs={12} sm={12} md={12} lg={6} xl={6} className="d-flex justify-content-around align-items-end" >
                <input onChange={(e)=>setImage(e.target.files[0])}  type="file" id="uploadImage" for="uploadImage" hidden />
                <label style={{ padding: "9px", borderRadius: "8px" , borderTopLeftRadius:"0px" , borderBottomLeftRadius : "0px" , minWidth : "100px "}} className='w-75  bg-yellow  text-white text-center font-fa fw-700 fs-12 cursor-pointer my-2' for="uploadImage"> انتخاب عکس </label>
                <button onClick={uploadImageHandler} style={{ padding: "8px", borderRadius: "8px", borderTopRightRadius:"0px" , borderBottomRightRadius : "0px" , minWidth : "70px" }} className='w-25  bg-light  text-yellow border-0 text-center font-fa fw-700 fs-12 cursor-pointer my-2'>آپلود <AiOutlineUpload /></button>
              </Col>

              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn disabled={formsubmit} onClick={submitWallet} title="ثبت اطلاعات ولت " />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AddWallet