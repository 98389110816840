import { useState , useEffect } from 'react'

import './index.scss'
import { Row, Col } from 'react-bootstrap'
import Search from '../../components/Search/Search'
import BackDrop from '../../components/BackDrop/BackDrop'
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import { toast, ToastContainer } from "react-toastify"
import { useSelector, useDispatch } from 'react-redux'

import AdminBtn from '../../components/AdminBtn'


const Admin = () => {
    // 
    const [token , setToken]=useState("")
    const [userOrder, setUserOrder] = useState(false)
    const [userList, setUserList] = useState(false)
    const [wallet, setWallet] = useState(false)
    const [sellOrder, setSellOrder] = useState(false)
    const [message, setMessage] = useState(false)
    const [department, setDepartment] = useState(false)
    const [crypto, setCrypto] = useState(false)
    const [auth, setAuth] = useState(false)
    const [bankCard, setBankCard] = useState(false)
    const [blog, setBlog] = useState(false)
    const [increaseCredit, setIncreaseCredit] = useState(false)
    const [acc, setAcc] = useState(false)
    const [setting, setSetting] = useState(false)
    const [ticket, setTicket] = useState(false)
    const [search, setSearch] = useState("")
    const [user, setUser] = useState([])
    const [idd, getId] = useState("")
    const [ admin , setAdmin ] = useState(false)



    const dispatch = useDispatch()



    useEffect(()=>{
        const getToken = JSON.parse(localStorage.getItem('token'));
        if (getToken) {
            setToken(getToken)
        }
    })

    const addAdmin = () => {
        fetch("http://api.pishroopardakht.com/api/user/addAdmin", {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify({
                id: idd,
                data: [admin,userOrder, sellOrder, increaseCredit, bankCard, setting, crypto, wallet, blog, department, acc, message, ticket, auth, userList]
            })
        }).
            then(async (res) => {

                if (res.status < 300) {
                    res = await res.json();
                    toast.success(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });


                } else {
                    res = await res.json();
                    toast.error(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(err => console.log(err))
    }

    const searchUser = () => {

        fetch("http://api.pishroopardakht.com/api/user/searchUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                search: search.trim()
            })
        }).
            then(async (res) => {

                if (res.status < 300) {
                    res = await res.json();
                    

                    if (res.users[0]) {
                        getId(res.users[0]._id)
                        if (res.users[0].priviliages[0]) setAdmin(true)
                        if (res.users[0].priviliages[1]) setUserOrder(true)
                        if (res.users[0].priviliages[2]) setSellOrder(true)
                        if (res.users[0].priviliages[3]) setIncreaseCredit(true)
                        if (res.users[0].priviliages[4]) setBankCard(true)
                        if (res.users[0].priviliages[5]) setSetting(true)
                        if (res.users[0].priviliages[6]) setCrypto(true)
                        if (res.users[0].priviliages[7]) setWallet(true)
                        if (res.users[0].priviliages[8]) setBlog(true)
                        if (res.users[0].priviliages[9]) setDepartment(true)
                        if (res.users[0].priviliages[10]) setAcc(true)
                        if (res.users[0].priviliages[11]) setMessage(true)
                        if (res.users[0].priviliages[12]) setTicket(true)
                        if (res.users[0].priviliages[13]) setAuth(true)
                        if (res.users[0].priviliages[14]) setUserList(true)
                    }


                    if (search) {

                        setUser([...res.users])
                        setSearch("")
                    } else {
                        setUser([])
                    }




                } else {
                    res = await res.json();
                    console.log(res)
                }
            }).catch(err => console.log(err))

    }
    return (
        <div className="ADMIN position-relative rounded-10 shadow bg-white m-1">
            <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="my-3 mx-4 d-flex flex-column align-items-center flex-md-row justify-content-md-start align-items-md-center">

                <div className="mx-2  d-flex  align-items-center">
                    {/* <span style={{ cursor: "pointer" }} onClick={searchUser} className='cursor-pointer mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z" fill="#F0B31D" />
                                <path d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z" fill="#F0B31D" />
                                <path d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z" fill="#F0B31D" />
                            </svg>
                        </span> */}
                    <Search value={search} onChange={(e) => { setSearch(e.target.value) }} onClick={searchUser} placeholder='جستجو كنيد ...' />
                </div>
            </div>

            {!user[0] &&
                <div className="w-100 p-4 d-flex flex-column justify-content-center align-items-center">
                    <div style={{ borderRadius: "16px" }} className="bg-yellow p-4 my-4">
                        <p className="font-fa fw-700 fs-18 text-white">لطفا ابتدا در قسمت جست و جو شماره مورد نظر را وارد کنید</p>
                    </div>
                    <span style={{ borderBottom: "1px solid #dcdcdc", width: "80%", margin: "auto" }}></span>
                </div>
            }

            {user[0] &&
                <div className='w-100 p-4 d-flex justify-content-center align-items-center'>
                    <Row className="w-100 d-flex justify-content-center align-items-center">
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="fw-700 fs-18 font-fa">نام و نام خانوادگی</p>
                                </div>
                                <div>
                                    <p className="fw-600 fs-15 font-fa text-yellow">{user[0] && user[0].name}</p>
                                </div>
                            </div>


                        </Col>
                        <span style={{ borderBottom: "1px solid #dcdcdc", width: "80%", margin: "auto" }}></span>
                        <Col className="d-flex justify-content-center items-center my-4" xs={12} sm={12} md={12} lg={8} xl={8}>
                            <form className="w-100 d-flex justify-content-start items-center">
                                <Row className=" w-100">
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setAdmin(d => !d) }} checked={admin} title="ادمین" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setUserOrder(d => !d) }} checked={userOrder} title="سفارسات خرید" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setSellOrder(d => !d) }} checked={sellOrder} title="سفارسات فروش" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setIncreaseCredit(d => !d) }} checked={increaseCredit} title="پرداخت افزایش اعتبار" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setBankCard(d => !d) }} checked={bankCard} title="کارت های بانکی" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setSetting(d => !d) }} checked={setting} title="تنظیمات" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setCrypto(d => !d) }} checked={crypto} title="رمز ارز" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setWallet(d => !d) }} checked={wallet} title="ولت" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setBlog(d => !d) }} checked={blog} title="بلاگ" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setDepartment(d => !d) }} checked={department} title="دیارتمان" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setAcc(d => !d) }} checked={acc} title="حساب" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setMessage(d => !d) }} checked={message} title="پیام" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setTicket(d => !d) }} checked={ticket} title="تیکت" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setAuth(d => !d) }} checked={auth} title="احراز هویت" />
                                    </Col>
                                    <Col className="d-flex justify-content-center items-center" xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <AdminBtn setChecked={() => { setUserList(d => !d) }} checked={userList} title="لیست کاربران" />
                                    </Col>

                                </Row>
                            </form>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <button onClick={addAdmin} className='w-50 saveChanges font-fa fw-600'>ذخیره تغیرات</button>
                        </Col>
                    </Row>
                </div>

            }






        </div>
    )
}


export default Admin