import React from 'react'

import Chat from '../../Chat/Chat'

const SupModal = (props) => {

    return (
        <div className="w-100">
            <div className='w-100'>
                <Chat  userId={props.id} />
            </div>
        </div>
    )
}

export default SupModal