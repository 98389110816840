import React  from 'react'

import './Input.scss'
const Input = ({
    title,
    value,
    onChange,
    type

}) => {
  
  return (
    <div className='w-100 INPUTSETTING d-flex flex-column align-items-start justify-content-end my-2'>
        <p className='fs-14 font-fa fw-700 mx-2'>{title}</p>
        <input className="INPUTSET w-100 p-2" type={type} value={value} onChange={onChange} />
    </div>
  )
}

export default Input