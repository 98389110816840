// import { useEffect } from "react";

// import axios from "axios";
// import server from "../../constants/_server";
// // import { toast } from "../../methods";
// import store from "../../redux";
// import Toast from '../../methods/_toast'
// import handleErrors from "./_handleErrors";





// axios.defaults.baseURL = `${server}/api`;
// const token = localStorage.getItem("token");
// axios.defaults.headers["Content-Type"] = "application/json";


// if (token !== null) {
//   axios.defaults.headers["Authorization"] = `Bearer ${token}`;
// }



// axios.interceptors.request.use((request) => {
//     store.dispatch({ type: "PUSH_LOADING" });
  
//   return request;
// });
// axios.interceptors.response.use(
//   (response) => {
//     store.dispatch({ type: "POP_LOADING" });
//     const text = response?.data?.message;
//     typeof text === "string" && Toast({ text })


//     return response;
//   }
//   ,
//   (error) => {
//     store.dispatch({ type: "POP_LOADING" });
//     const status = error?.response?.status;
//     const message = status ? `Error ${status}` : "Network Error";
//     const text = error?.response?.data?.message ?? message;
//     Toast({ type: "error", text });

//     return Promise.reject(error);
//   }
// );
// export default axios;




import axios from "axios";
import store from "../../redux";
import handleErrors from "./_handlerror";
import  server  from "../../constants/_server";

const token = JSON.parse(localStorage.getItem('token'));



axios.defaults.baseURL = `${server}/api/`;
axios.defaults.headers["Content-Type"] = "application/json";
if (token !== null) {
  axios.defaults.headers.common['Authorization'] = token;
}

const unLoadingUrl = ["/activity-logs/time"];
const v1Urls = ["/pub/categories", "/pub/videos"];

const handleLoading = (type = "", url = "", progress) => {
  unLoadingUrl.includes(url) || store.dispatch({ type, progress });
};

axios.interceptors.request.use(
  (request) => {
    handleLoading("SHOW_LOADING", request.url);
    if (v1Urls.some((e) => request.url.search(e) !== -1)) {
      request.baseURL = `${server}/api`;
    }
    request.onUploadProgress = (e) => {
      const { loaded, total } = e;
      const progress = Math.floor((loaded / total) * 100);
      handleLoading("SET_LOADING_PROGRESS", null, progress);
    };
    return request;
  },
  (error) => {
    handleLoading("SHOW_LOADING");
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    handleLoading("HIDE_LOADING");
    return response;
  },
  (error) => {
    handleErrors(error);
    handleLoading("HIDE_LOADING");
    return Promise.reject(error);
  }
);

export default axios;

