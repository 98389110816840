import React, { useEffect , useState } from 'react'

import { Row, Col, Button } from 'react-bootstrap'
import './UserOrderModal.scss'
import { toast, ToastContainer } from "react-toastify"
import useEditInput from '../../../hooks/useEditInput'
import { useSelector, useDispatch } from 'react-redux'



const UserOrderModal = (props) => {
    const cssClass = ["userOrdermodal", props.show ? "ModalOpen" : "ModalClose"]
    const [ token , setToken ] = useState("")

    const dispatch = useDispatch()
    useEffect(()=>{
        const getToken = JSON.parse(localStorage.getItem('token'));
        if (getToken) {
            setToken(pre => pre = getToken);
        }
    })
    const textId = useEditInput(props.item.txid)
    const adminMess = useEditInput(props.item.adminDecription)

    const accBuyOrder = (enter) => {
        fetch("http://api.pishroopardakht.com/api/order/orderacceptBuyOrder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify({
                id : props.item._id,
                status : enter,
                txid : textId.value,
                adminDecription : adminMess.value
            })
        }).
            then(async (res) => {
                console.log(res.status)
                if (res.status < 300) {
                    res = await res.json();
                    console.log(res)
                    toast.success(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                } else {
                    res = await res.json();
                    console.log(res)
                    toast.error(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            }).catch(err => console.log(err))
    }


    const orderAccBuy = (enter) => {
        fetch("http://api.pishroopardakht.com/api/order/payacceptBuyOrder", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify({
                id : props.item._id,
                status : enter
            })
        }).
            then(async (res) => {
                console.log(res.status)
                if (res.status < 300) {
                    res = await res.json();
                    console.log(res)
                    toast.success(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    res = await res.json();
                    console.log(res)
                    toast.error(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            }).catch(err => console.log(err))
    }
    return (
        <div className={cssClass.join(" ")}>
            
            {/* <button className='fw-700 fs-15' style={{ position: "absolute", top: "10px", right: "10px", border: "none", background: " none" }} > <AiFillCloseCircle /> </button> */}
            <Row className='d-flex justify-content-start align-items-center my-4 px-4'>
                {/* <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={name.value} onChange={name.onChange} title="نام" />
                </Col> */}
                <Col className='d-flex justify-content-between align-items-center m-2' xs={12} md={12} sm={12} lg={12} xl={12} >

                    <div className='mx-2 font-fa fw-700 fs-15'>

                        <p>نام و نام خانوادگی <br /> سفارش دهنده</p>
                    </div>

                    <div className='mx-2 font-fa fw-500 fs-15'>
                        <p>{props.item.user && props.item.user.name}</p>
                    </div>
                </Col>

                <Col className='d-flex justify-content-between align-items-center m-2' xs={12} md={12} sm={12} lg={12} xl={12} >

                    <div className='mx-2 font-fa fw-700 fs-15'>
                        <p>ثبت سفارش برای</p>
                    </div>

                    <div className='mx-2 font-fa fw-500 fs-15'>
                        <p>{props.item.coin && props.item.coin.symbol}  {props.item.coinAmount} </p>
                    </div>
                </Col>

                <Col className='d-flex justify-content-between align-items-center m-2' xs={12} md={12} sm={12} lg={12} xl={12} >

                    <div className='mx-2 font-fa fw-700 fs-15'>
                        <p>توضيحات</p>
                    </div>

                    <div className='mx-2 font-fa fw-500 fs-15'>
                        <p>{props.item.userDecription}</p>
                    </div>
                </Col>

                <span className='dash'></span>


                <Col className='d-flex flex-column flex-md-row align-items-center justify-content-md-between align-items-md-center m-2' xs={12} md={12} sm={12} lg={12} xl={12} >

                    <div className='mx-2 font-fa fw-700 fs-15'>
                        <p>والت کاربر</p>
                    </div>

                    <div className='mx-2 font-fa fw-500 fs-12'>
                        <p>{props.item.userWallet}</p>
                    </div>
                </Col>


                <Col className='d-flex justify-content-between align-items-center m-2' xs={12} md={12} sm={12} lg={12} xl={12} >

                    <div className='mx-2 font-fa fw-700 fs-15'>
                        <p>شبکه</p>
                    </div>

                    <div className='mx-2 font-fa fw-500 fs-15'>
                        <p>{props.item.network && props.item.network.name }</p>
                    </div>
                </Col>


                <Col className='d-flex flex-column flex-md-row align-items-center justify-content-md-between align-items-md-center m-2' xs={12} md={12} sm={12} lg={12} xl={12} >

                    <div className='mx-2 my-2 font-fa fw-700 fs-15'>
                        <p>شماره txID</p>
                    </div>

                    <div className='mx-2 font-fa fw-500 fs-15'>
                        <textarea value={textId.value} onChange={textId.onChange} rows="3" cols="25" ></textarea>
                    </div>
                </Col>

                <Col className='d-flex flex-column flex-md-row align-items-center justify-content-md-between align-items-md-center m-2' xs={12} md={12} sm={12} lg={12} xl={12} >

                    <div className='mx-2 font-fa fw-700 fs-15'>
                        <p>پیام مدیریت</p>
                    </div>

                    <div className='mx-2 font-fa fw-500 fs-15'>
                        <textarea value={adminMess.value} onChange={adminMess.onChange} rows="3" cols="25" ></textarea>
                    </div>
                </Col>

                <span className='dash2'></span>


                <Col className='my-2' xs={12} md={12} sm={12} lg={12} xl={12} >
                    <Button onClick={()=>{accBuyOrder(2);props.closed()}} className='fw-14 fw-500 font-fa m-1' variant="danger">رد سفارش</Button>
                    <Button onClick={()=>{accBuyOrder(1);props.closed()}} className='fw-14 fw-500 font-fa m-1' variant="success">تایید سفارش</Button>
                    <Button onClick={()=>{accBuyOrder(0);props.closed()}} className='fw-14 fw-500 font-fa m-1' variant="info">در انتظار بررسی</Button>
                    <Button className='fw-14 fw-500 font-fa m-1' onClick={props.closed} variant="secondary">انصراف</Button>
                </Col>

                {/* <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={email.value} onChange={email.onChange} title="ایمیل" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={codeMeli.value} onChange={codeMeli.onChange} title="کد ملی" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={address.value} onChange={address.onChange} title="آدرس" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={phoneSabet.value} onChange={phoneSabet.onChange} title="شماره ثابت" />
                </Col> */}
            </Row>
        </div>
    )
}

export default UserOrderModal