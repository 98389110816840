import Toast from "../../methods/_toast";
import {toast} from "react-toastify"

export default function handleErrors(error) {
    console.log(error?.response);
    const response = error?.response;
    if (!response) return toast.error("Network error");
    const errorsList = response.data?.errors ?? [];
    const errorCode = response.data?.code;
    Toast({ text : response.data?.message , type : "error" });
    errorsList.forEach((error) => {
      const text = Object.values(error)[0];
    
      Toast({ text : text, type: "error" });
    });
    // const text = errors[errorCode];
    // if (text) return toast({ text, type: "error" });
  }