import React from 'react'

import './ItemSentModal.scss'

const ItemSentModal = (props) => {
    const cssStyle = ["ITEMSENT" , props.show ? "ModalOpen" : "ModalClose"]

    return(
        <div className={cssStyle.join(" ")}>
            <button onClick={props.closed} >close</button>
        </div>
    )
}

export default ItemSentModal