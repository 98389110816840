import React from 'react'

import './Button.scss'

const Button = ({
  title,
  onClick,
  disabled,
  className
}) => {
  return (
    <button onClick={onClick} disabled={disabled} className={`ButtonSelf font-fa fw-700 fs-14 ${className}`}>
        {title}
    </button>
  )
}

export default Button