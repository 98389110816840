import React, { useState, useEffect } from 'react'


import Btn from '../../components/settingg/BTN/Btn'
import Input from '../../components/settingg/Input/Input'
import useInput from '../../hooks/useInput'
import useEditInput from '../../hooks/useEditInput'
import { toast, ToastContainer } from "react-toastify"
import { Row, Col } from 'react-bootstrap'
import './Setting.scss'
import { AiOutlineDollar } from "react-icons/ai"

function Setting() {
  const [sellUsdt, setSellUsdt] = useState("")
  const [buyUsdt, setBuyUsdt] = useState("")

  var formatter = new Intl.NumberFormat('fa-IR', {
    currency: 'IRR',
  });

  useEffect(() => {
    fetch("http://api.pishroopardakht.com/api/tether/getusdtprice", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).
      then(async (res) => {
        
        if (res.status < 300) {
          res = await res.json();
         
          setSellUsdt(res[0].sellPrice)
          setBuyUsdt(res[0].buyPrice)


        } else {
          res = await res.json();
          console.log(res)
        }
      }).catch(err => console.log(err))


  }, [])






  //-------------------department--------------------------
  // const {
  //   value: depart,
  //   isValid: enterdepartIsValid,
  //   hasError: departInputHasError,
  //   valueChangeHandler: handledepartChange,
  //   inputBlurHandler: handledepartBlur,
  //   reset: resetdepart
  // } = useInput(value => value.trim() !== "")

  // const handleDepart = async () => {
  //   try {
  //     const resAPI = await fetch("https://api.pishroopardakht.com/api/support/addDepartment", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },

  //       body: JSON.stringify({
  //         name: depart
  //       })

  //     })
  //     console.log(resAPI.status)
  //     if (resAPI.status < 300) {
  //       const res = await resAPI.json();
  //       toast.success(res.message, {
  //         position: "top-left",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       resetmessage()
  //       resetmessageBody()
  //     } else {
  //       const res = await resAPI.json();
  //       toast.error(res.message, {
  //         position: "top-left",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  //-------------------department--------------------------




  //-------------------sell Tether--------------------------
  const {
    value: sellTether,
    isValid: entersellTetherIsValid,
    hasError: sellTetherInputHasError,
    valueChangeHandler: handlesellTetherChange,
    inputBlurHandler: handlesellTetherBlur,
    reset: resetsellTether
  } = useInput(value => value.trim() !== "")

  const handleSellTether = async () => {
    try {
      const resAPI = await fetch("https://api.pishroopardakht.com/api/tether/addsellprice", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },

        body: JSON.stringify({
          price: sellTether
        })

      })
      if (resAPI.status < 300) {
        const res = await resAPI.json();
        toast.success(res.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        resetsellTether()
      } else {
        const res = await resAPI.json();
        toast.error(res.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error)
    }
  }

  //-------------------sell Tether--------------------------




  //-------------------buy Tether--------------------------
  const {
    value: buyTether,
    isValid: enterbuyTetherIsValid,
    hasError: buyTetherInputHasError,
    valueChangeHandler: handlebuyTetherChange,
    inputBlurHandler: handlebuyTetherBlur,
    reset: resetbuyTether
  } = useInput(value => value.trim() !== "")

  const handleBuyTether = async () => {
    try {
      const resAPI = await fetch("https://api.pishroopardakht.com/api/tether/addbuyprice", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },

        body: JSON.stringify({
          price: buyTether
        })

      })
      if (resAPI.status < 300) {
        const res = await resAPI.json();
        toast.success(res.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        resetbuyTether()
      } else {
        const res = await resAPI.json();
        toast.error(res.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error)
    }
  }

  //-------------------buy Tether--------------------------




  //------------------- Rule --------------------------

  const {
    value: Rule,
    isValid: enterRuleIsValid,
    hasError: RuleInputHasError,
    valueChangeHandler: handleRule,
    inputBlurHandler: handleRuleBlur,
    reset: resetRuleInput
  } = useInput(value => value.trim() !== "")


  const submitRule = async () => {

    try {
      const resAPI = await fetch("http://api.pishroopardakht.com/api/term/addTerm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },

        body: JSON.stringify({
          content: Rule

        })

      })
      
      if (resAPI.status < 300) {
        const res = await resAPI.json();
        
        
        toast.success(res.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });



        resetRuleInput()
      } else {
        const res = await resAPI.json();
        console.log(res)
        toast.error(res.message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }


    } catch (error) {

    }
  }

  //------------------- Rule --------------------------





  return (
    <div className='SETTING  m-1'>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Row className="d-flex justify-content-center align-items-center">

        {/* <Col className="" xs={12} sm={12} md={12} lg={6} xl={6} >
          <Row>
            

            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
              <div className=' w-100 p-2 setting-div shadow mx-1 my-1'>
                <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
                  <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>تعيين آدرس هاي كيف پول</p>
                </div>
                <Row className="mx-1 my-5">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Input title="tether" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Input title="ripple" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Input title="litecoin" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Input title="chainlink" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Input title="bitcoin" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Input title="ethereum" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Input title="petronas" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <Input title="binancecoin" />
                  </Col>
                  <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Btn title="ثبت اطلاعات پتروناس" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
              <div className=' w-100 p-2 setting-div shadow mx-1 my-1'>
                <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
                  <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>متن احراز هويت ويدئويي</p>
                </div>
                <Row className="mx-1 my-5">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    <textarea rows="5" className="w-100"></textarea>
                  </Col>
                  
                  <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Btn title="ثبت حداكثري" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col> */}

        <Col className="d-flex justify-content-center align-items-center" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-2 setting-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>تعيين قيمت خرید تتر  </p>
            </div>
            <Row className="mx-1 my-5">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                {/* <p className='fw-600 fs-15 font-fa text-center my-2'>قیمت خرید</p>
                <p className="fw-500 fs-12 font-fa text-center my-2">{buyUsdt}</p> */}
                <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                  <span><p className='fw-600 fs-15 font-fa text-center m-2'>قیمت خرید</p></span>
                  <span className="bg-yellow shadow rounded p-2 m-2 text-white fw-500 font-fa ">{formatter.format(buyUsdt)} تومان </span>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <Input value={buyTether} onChange={handlebuyTetherChange} title="قيمت خرید تتر" />
              </Col>
              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn disabled={!buyTether} onClick={handleBuyTether} title="ثبت قيمت تتر" />
              </Col>
            </Row>
          </div>
        </Col>



        <Col className="d-flex justify-content-center align-items-center" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-2 setting-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>تعيين قيمت فروش تتر  </p>
            </div>
            <Row className="mx-1 my-5">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                {/* <p className='fw-600 fs-15 font-fa text-center my-2'>قیمت خرید</p>
                <p className="fw-500 fs-12 font-fa text-center my-2">{buyUsdt}</p> */}
                <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
                  <span><p className='fw-600 fs-15 font-fa text-center m-2'>قیمت فروش</p></span>
                  <span className="bg-yellow shadow rounded p-2 m-2 text-white fw-500 font-fa ">{formatter.format(sellUsdt)} تومان </span>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <Input value={sellTether} onChange={handlesellTetherChange} title="قيمت فروش تتر" />
              </Col>
              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn disabled={!sellTether} onClick={handleSellTether} title="ثبت قيمت تتر" />
              </Col>
            </Row>
          </div>
        </Col>
        {/* <Col className="d-flex justify-content-center align-items-center" xs={12} sm={12} md={12} lg={6} xl={6} >
              <div className=' w-100 p-2 setting-div shadow mx-1 my-1'>
                <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
                  <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>تعيين قيمت دلار </p>
                </div>
                <Row className="mx-1 my-5">
                  <Col xs={12} sm={12} md={12} lg={6} xl={6} className="">
                    <Input title="قيمت فروش دلار" />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6} xl={6} className="">
                    <Input title="قيمت خريد دلار" />
                  </Col>
                  <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Btn title="ثبت قيمت ها" />
                  </Col>
                </Row>
              </div>
            </Col> */}


        {/* <Col className="d-flex justify-content-center align-items-center" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-4 setting-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>دیپارتمان</p>
            </div>
            <Row className="mx-1 my-5">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <Input onChange={handledepartChange} value={depart} title="عنوان" />
              </Col>
              <Col className="d-flex justify-content-center my-4" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn onClick={handleDepart} title="ثبت دیپارتمان" />
              </Col>
            </Row>
          </div>
        </Col> */}

        <Col className="d-flex justify-content-center align-items-center" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-4 setting-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>تعيين امتياز خريد</p>
            </div>
            <Row className="mx-1 my-5">
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className="my-2">
                <Input title="امتياز هر خريد بالاتر از مبلغ" />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className="my-2">
                <Input title="مقدار هر امتياز" />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className="my-2">
                <Input title="امتياز دعوت هر كاربر" />
              </Col>

              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn title="ثبت اطلاعات حساب" />
              </Col>
            </Row>
          </div>
        </Col>
        

        <Col className="d-flex justify-content-center align-items-center" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-2 setting-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>قوانين سايت</p>
            </div>
            <Row className="mx-1 my-5">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                <p className='fs-14 font-fa fw-700 my-2 mX-2'>قوانين و مقررات</p>
                <textarea value={Rule} onChange={handleRule} rows="5" className="w-100"></textarea>
              </Col>

              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn disabled={!Rule} onClick={submitRule} title="ثبت قوانین" />
              </Col>
            </Row>
          </div>
        </Col>



      </Row>
    </div>
  )
}

export default Setting