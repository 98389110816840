import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Allpay, Onlinepay, Offlinepay } from "./components";
import Context from "./_context";
import './IncreaseCredit.scss'


const IncreaseCredit = () => {
    const [page, setPage] = useState("all");
    const [ token , setToken ] = useState("")


    useEffect(()=>{
        const getToken = JSON.parse(localStorage.getItem('token'));
    if (getToken) {

      setToken(getToken);
    }
    })
    return (
        <div className="w-100">
            <div className="INCREASECREDIT position-relative rounded-10 shadow bg-white m-1">
                <div className="w-100 p-1 my-1">
                    <Context.Provider value={[page, setPage]}>
                        {page == "all" && <Allpay token={token} />}
                        {page == "online" && <Onlinepay />}
                        {page == "offline" && <Offlinepay />}
                    </Context.Provider>

                </div>
            </div>
        </div>
    )
}

export default IncreaseCredit