import { toast } from "react-toastify"

const Toast = ({ text = null,type = "success",duration = 5000, }) => {
    const defaultMessages = {
        success: "عملیات با موفقیت انجام شد.",
        error: "",
      };
    const message = text ?? defaultMessages[type];
    toast[type](message, {
        position: "top-center",
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export default Toast;