import React, { useState , useEffect } from 'react'

import './EditWalletModal.scss'
import Axios from 'axios';
import Input from '../../settingg/Input/Input'
import Btn from '../../settingg/BTN/Btn'
import { Col, Row } from 'react-bootstrap'
import { AiFillCloseCircle, AiOutlineUpload } from "react-icons/ai"
import { toast, ToastContainer } from "react-toastify"
import useEditInput from '../../../hooks/useEditInput'
import BackDrop from '../../BackDrop/BackDrop'
import PicSpinner from '../../picSpinner/PicSpinner'


const EditWalletModal = (props) => {
    const [ token,setToken ] = useState("")
    const cssStyle = ["EDITWALLETMODAL", props.show ? "ModalOpen" : "ModalClose"]

    useEffect(() => {

        const getToken = JSON.parse(localStorage.getItem('token'));
        if (getToken) {
            setToken(getToken)
        }
    }, [])

    const [image, setImage] = useState(null)
    const [show, setShow] = useState(false)
    const [qrAddress, setQrAddress] = useState("")

    let data = new FormData();

    // const {
    //     value: name,
    //     isValid: enternameIsValid,
    //     hasError: nameInputHasError,
    //     valueChangeHandler: handlename,
    //     inputBlurHandler: handlenameBlur,
    //     reset: resetnameInput
    //   } = useInput(value => value.trim() !== "")

    const name = useEditInput(props.item.name)
    const symbol = useEditInput(props.item.symbol)

    const fee = useEditInput(props.item.fee)
    const mem = useEditInput(props.item.mem)
    const address = useEditInput(props.item.address)






    const editWallet = () => {
        fetch("http://api.pishroopardakht.com/api/wallet/editWallet", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify({
                id: props.item._id,
                name: name.value,
                symbol: symbol.value,
                fee: fee.value,
                mem: mem.value,
                address: address.value,
                qrCodeimage: qrAddress
            })
        }).
            then(async (res) => {
                if (res.status < 300) {
                    res = await res.json();
                    toast.success(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                } else {
                    res = await res.json();
                    toast.error(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(err => console.log(err))
    }

    const uploadImageHandler = () => {
        setShow(true)
        data.append("image", image);
        Axios(
            {
                method: "POST",
                url: `http://api.pishroopardakht.com/api/wallet/uploadqrimage`,
                data,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": token
                }
            }

        )
            .then(res => {
                if (res.status < 300) {
                    console.log(res);
                    setShow(false)
                    setQrAddress(res.data.address)
                    toast.success(res.data.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    console.log(res)
                    toast.error(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            })
    }

    const openModal = () => {
        setShow(true)
    }

    const closeModal = () => {
        setShow(false)
    }

    return (
        <div className={cssStyle.join(" ")}>
            {show && <PicSpinner show={show} closed={closeModal} title="عکس در حال آپلود است" />}
            {show && <BackDrop show={show} />}
            <button className='fw-700 fs-15' onClick={props.closed} style={{ position: "absolute", top: "10px", right: "10px", border: "none", background: " none" }} > <AiFillCloseCircle /> </button>
            <Row className='d-flex justify-content-start align-items-center my-4'>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={name.value} onChange={name.onChange} title="نام" />
                </Col>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={symbol.value} onChange={symbol.onChange} title="سینبل" />
                </Col>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={fee.value} onChange={fee.onChange} title="fee" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={mem.value} onChange={mem.onChange} title="mem" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={address.value} onChange={address.onChange} title="Address" />
                </Col>

                <Col xs={12} sm={12} md={12} lg={6} xl={6} className="" >
                    <div className='w-100'>
                        <p className='text-end mx-1 font-fa fw-700 my-1'>آپلود عکس</p>
                        <input onChange={(e) => setImage(e.target.files[0])} type="file" id="uploadImage" for="uploadImage" hidden />
                        <label style={{ padding: "9px", borderRadius: "8px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px" }} className='w-75  bg-yellow  text-white text-center font-fa fw-700 fs-12 cursor-pointer my-2' for="uploadImage"> انتخاب عکس </label>
                        <button onClick={uploadImageHandler} style={{ padding: "8px", borderRadius: "8px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }} className='w-25  bg-light  text-yellow border-0 text-center font-fa fw-700 fs-12 cursor-pointer my-2'>آپلود <AiOutlineUpload /></button>
                    </div>

                </Col>

                <Col xs={12} md={12} sm={12} lg={12} xl={12} >
                    <Btn onClick={editWallet} title="ذخیره" />
                </Col>
            </Row>
        </div>
    )
}

export default EditWalletModal