import React, { useState, useEffect } from 'react'

import './Auth.scss'
import cloneDeep from "lodash/cloneDeep";
import Search from '../../components/Search/Search'


import { Button, Row, Col } from 'react-bootstrap'
import { css } from "@emotion/react";
import Pagi from '../../components/Pagi/Pagi'
import axios from '../../boot/axios'
import Modal from '../../components/NewModal'
import { useSelector, useDispatch } from 'react-redux'
import Toast from '../../methods/_toast'
import Form from '../../components/Form'
import Input from '../../components/Input'




function Auth() {
    const [data, setData] = useState({});
    const [getUser, setGetUser] = useState([])
    const [show, setShow] = useState(false)
    const [img, setImg] = useState();
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [show2, setShow2] = useState(false)
    
    // useEffect(() => {


    // fetch("http://api.pishroopardakht.com/api/user/getAuthList", {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Authorization": `Bearer ${test}`
    //     },
    //     body: JSON.stringify({
    //         limit: 10,
    //         page
    //     })

    // }).
    //     then(async (res) => {

    //         if (res.status < 300) {
    //             res = await res.json();

    //             setTotal(res.totalPages)
    //             setLoading(false)
    //             setGetUser([...res.users])
    //         } else {
    //             res = await res.json();

    //         }
    //     }).catch(err => console.log(err))
    // }, [])



    const getAuth = () => {
        const url = "user/getAuthList"
        const body = {
            limit: 10,
            page
        };
        axios.post(url, body).then(({ data }) => {
            setTotal(data.totalPages)

            setGetUser([...data.users])
        });
    }

    useEffect(getAuth, [page])

    const searchAuth = () => {
        const url = "user/searchUser"
        const body = {
            search: search.trim()
        }
        axios.post(url, body).then(({ data }) => {
            setGetUser([...data.users])
            setSearch("")
            if (data.users?.length == 0) {
                Toast({ type: "error", text: "کاربر یافت نشد" })
            }
        })
    }


    // const searchAuth = () => {

    //     fetch("http://api.pishroopardakht.com/api/user/searchUser", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         },
    //         body: JSON.stringify({
    //             search: search.trim()
    //         })
    //     }).
    //         then(async (res) => {

    //             if (res.status < 300) {
    //                 res = await res.json();

    //                 if (res.users.length < 1) {

    //                 } else {
    //                     setGetUser([...res.users])
    //                     setSearch("")
    //                 }




    //             } else {
    //                 res = await res.json();
    //                 console.log(res)
    //             }
    //         }).catch(err => console.log(err))

    // }

    const accAuth = (a) => {
        const url = "user/acceptAuth"
        const body = cloneDeep(data);
        body.authentication = a
        
        axios.post(url, body).then(({ data }) => {
            Toast({ text: data.message })
            setData({})

        })

    }

    const option = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    }

    const formControls = [

        {
            as: "textarea",
            label: "توضیحات",
            state: "description",
        },
    ];

    return (
        <div>
            <Modal
                show={show2}
                onHide={setShow2}
                title="ویرایش مشخصات کاربر"
                size="md"
                type='yellow'

            >
                <Row className="p-2 w-100">
                    <Col className="d-flex justify-content-center w-100" xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* '<p className='mx-2 my-2 font-fa fw-700 fs-15'>پیام مدیریت</p>
                        <textarea value={adminMess.value} onChange={adminMess.onChange} className='rounded w-100 shadow font-fa p-2' rows="4" cols="40" placeholder='لطفا دلیلتان را بنویسید ...' ></textarea>' */}
                        <Form className="row" >
                            {formControls.map((e) => (
                                <Col
                                    key={e.state}
                                    xs="12"
                                    md={e.state === "description" ? "12" : "6"}
                                    lg={e.state === "description" ? "12" : "4"}
                                >
                                    {React.createElement(e.tag ?? Input, {
                                        ...e,
                                        value: data[e.state],
                                        setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                                    })}
                                </Col>
                            ))}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button  onClick={() => accAuth( 1)} className='fw-500 fs-14 font-fa mx-1 px-4' variant='outline-success'>تایید</Button>
                                <Button  onClick={() => accAuth(2)} className='fw-500 fs-14 font-fa mx-1 px-4' variant='outline-danger'>رد</Button>
                            </Col>
                        </Form>
                    </Col>

                </Row>
            </Modal>

            <Modal
                show={show}
                onHide={setShow}
                size="lg"
                type='yellow'
                >

                <div className='w-100 d-flex justify-content-center align-items-center'>
                    <img className='IMGreceipt' width="100%" height="100%" src={img} alt="receipt" />
                </div>

            </Modal>
            <div className="AUTH position-relative rounded-10 shadow bg-white m-1">


                {/* {show && <ModalPic src={img} show={show} closed={closeModal} />} */}
                <div className="my-3 mx-4 d-flex flex-column align-items-center flex-md-row justify-content-md-end align-items-md-center">

                    <div className="mx-2  d-flex  align-items-center">
                        <Search value={search} onChange={(e) => { setSearch(e.target.value) }} onClick={searchAuth} placeholder='جستجو كنيد ...' />
                    </div>
                </div>

                <div className="w-100 p-1 my-1">
                    <div className="AUTHList">

                        <table className="w-100 ">
                            <thead className='bg-white'>
                                <tr className='font-fa bg-white'>
                                    <th className='mx-2'>#</th>
                                    <th className=''>نام و نام خانوادگي</th>
                                    <th className=''>شماره تماس</th>
                                    <th className=''>کد ملی</th>
                                    <th className=''>ایمیل</th>
                                    <th className=''>شماره تلفن ثابت</th>
                                    <th className=''>عکس کارت ملی</th>
                                    <th className=''>احراز هویت</th>

                                    <th className=''>آدرس</th>
                                    <th className=''>شهر</th>
                                    <th className=''>تاریخ ارسال</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr  className='font-fa'>
                                    <td className="fs-12 text-tableTd">1</td>
                                    <td className="fs-12 text-tableTd">amir</td>

                                    <td className="fs-12 text-tableTd">09101411633</td>
                                    {/* <td className="fs-12 text-tableTd">amir.valipouri@yahoo.com</td> 
                                    <td className="fs-12 text-tableTd"><img onClick={()=>{openModal();setImg("https://www.w3schools.com/images/w3schools_green.jpg")}} style={{cursor : "zoom-in"}} src='https://www.w3schools.com/images/w3schools_green.jpg' alt='meli' width="60" height="60" /></td>
                                    <td className="fs-12 text-tableTd"><img onClick={()=>{openModal();setImg("https://www.jquery-az.com/html/images/banana.jpg")}} style={{cursor : "zoom-in"}} src="https://www.jquery-az.com/html/images/banana.jpg" alt='meli' width="60" height="60" /></td>
                                    <td className="fs-12 text-tableTd">12.15.98</td>
                                    <td className=''>
                                        <button className='rejAuth font-fa fw-700 fs-14 mx-1 my-2'>رد کردن</button>
                                        <button className='accAuth font-fa fw-700 fs-14 my-2'>تایید کردن</button>

                                    </td>

                                </tr> */}
                                {getUser && getUser.map((i, index) =>
                                    i.authentication == 1 ?
                                        <tr style={{ backgroundColor: "#54ECC4" }} key={i._id} className='font-fa'>
                                            <td className="fs-12 text-white p-2">{index + 1}</td>
                                            <td className="fs-12 text-white p-2">{i.name}</td>
                                            <td className="fs-12 text-white p-2">{i.phone}</td>
                                            <td className="fs-12 text-white p-2">{i.codeMelli}</td>
                                            <td className="fs-12 text-white p-2">{i.email}</td>
                                            <td className="fs-12 text-white p-2">{i.phoneSabet}</td>
                                            <td className="fs-12 text-white p-2"><img onClick={() => { setShow(true); setImg(`http://api.pishroopardakht.com${i.melliCardImage}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.melliCardImage}`} alt='meli' width="60" height="60" /></td>
                                            <td className="fs-12 text-white p-2"><img onClick={() => { setShow(true); setImg(`http://api.pishroopardakht.com${i.authenticationPic}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.authenticationPic}`} alt='auth' width="60" height="60" /></td>
                                            <td className="fs-12 text-white p-2">{i.address}</td>
                                            <td className="fs-12 text-white mx-2">{i.city}</td>

                                            <td className="fs-12 text-white">{new Date(i.updatedAt).toLocaleDateString("fa-IR", option)}</td>
                                            <td className=''>
                                                {/* <button onClick={() => { accAuth(i.phone, 2) }} className='rejAuth font-fa fw-700 fs-14 mx-1 my-2'>رد کردن</button> */}
                                                <button onClick={() => { setShow2(true); setData((p)=>({...p , phone : i.phone}))  }} className='accRej   font-fa fw-600 fs-14 my-2'>تایید / رد</button>
                                            </td>

                                        </tr>

                                        :

                                        <tr key={i._id} className='font-fa '>
                                            <td className="fs-12 text-tableTd p-2">{index + 1}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.name}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.phone}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.codeMelli}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.email}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.phoneSabet}</td>
                                            <td className="fs-12 text-tableTd p-2"><img onClick={() => { setShow(true); setImg(`http://api.pishroopardakht.com${i.melliCardImage}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.melliCardImage}`} alt='meli' width="60" height="60" /></td>
                                            <td className="fs-12 text-tableTd p-2"><img onClick={() => { setShow(true); setImg(`http://api.pishroopardakht.com${i.authenticationPic}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.authenticationPic}`} alt='auth' width="60" height="60" /></td>
                                            <td className="fs-12 text-tableTd p-2">{i.address}</td>
                                            <td className="fs-12 text-tableTd mx-2">{i.city}</td>

                                            <td className="fs-12 text-tableTd">{new Date(i.updatedAt).toLocaleDateString("fa-IR", option)}</td>
                                            <td className=''>
                                                {/* <button onClick={() => { accAuth(i.phone, 2) }} className='rejAuth font-fa fw-700 fs-14 mx-1 my-2'>رد کردن</button> */}
                                                <button onClick={() => { setShow2(true);setData((p)=>({...p , phone : i.phone})) }} className='accRej  font-fa fw-600 fs-14 my-2'>تایید / رد</button>
                                            </td>

                                        </tr>


                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            <div className="w-100 my-3  d-flex flex-column align-items-center flex-md-row justify-content-md-around align-items-md-center">
                <Pagi activePage={page} setActivePag={setPage} totalPage={total} />


                <div>
                    <p className='d-inline-block mx-1 fw-400 fs-10'>496/706</p>
                    <p className='d-inline-block mx-3 fw-400 fs-10'>:ACTIVE CUSTOMERS </p>
                </div>
            </div>
        </div>
    )
}

export default Auth