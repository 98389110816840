import React, { useState, useEffect } from 'react'

import './Sidebar.scss'
import { Dropdown, NavDropdown, Nav } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../hooks/useWindowDimensions '
import Profilepic from '../../assets/profile.png'
import { SidebarData } from './SidebarData'
import { useSelector, useDispatch } from 'react-redux'
import SubMenu from './SubMenu'
import { toast, ToastContainer } from "react-toastify"
import { NavLink } from 'react-router-dom'


const Sidebar = ({
    onHide = () => { }
}) => {
    const [show, setShow] = useState(1)
    const [user, setUser] = useState({})
    const { height, width } = useWindowDimensions();
    let test = ""
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const getToken = JSON.parse(localStorage.getItem('token'));
        const getUser = JSON.parse(localStorage.getItem("user"))

        setUser(getUser)
        if (getToken) {
            test = getToken
        }
    }, [])
    let sideResponse;
    if (width > 900) {
        sideResponse = false
    } else {
        sideResponse = true
    }
    return (
        <div className="sidebar-content position-relative bg-dark ">
            <div className="h-100 overflow-auto px-2 py-2 text-white">
                
                <div className='mx-2 d-block d-lg-none'>
                    <img src={Profilepic} alt="pofile photo" />
                </div>
                <div className='w-100  p-3'>
                    <ul className='my-2 mx-2 list-unstyled'>
                        
                        {SidebarData.map((i, index) => {
                            return <SubMenu item={i} key={index} />;
                        })}

                        <li onClick={() => {
                            toast("Logout successfully", {
                                position: "top-center",
                                autoClose: 4000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }); setTimeout(() => { navigate("/login"); localStorage.clear();  }, 2000)
                        }} className='sideLi fs-14 fw-900 my-2 font-fa cursor-pointer'>خروج از حساب</li>


                    </ul>


                </div>

            </div>


            <button
                className="hide-btn flex-1 d-block d-lg-none opacity-0"
                onClick={() => onHide(false)}
            />
        </div>
    )
}

export default Sidebar