import React, { useState, useEffect } from 'react'

import Axios from 'axios';
import "./Chat.scss";
import { IoIosSend } from 'react-icons/io'
import { Row, Col } from "react-bootstrap"
import useInput from "../../hooks/useInput"
import { toast, ToastContainer } from "react-toastify"
import { GrFormUpload } from 'react-icons/gr'
import ModalPic from '../modal/picture/ModalPic';

const Chat = ({ userId , refresh }) => {

    const [token, setToken] = useState("")
    const [file, setFile] = useState("")
    const [chat, setChat] = useState([])
    const [click, setClick] = useState(0)
    const [show, setShow] = useState(false)
    const [img, setImg] = useState(null)


    const openModal = () => {
        setShow(true)
    }

    const closeModal = () => {
        setShow(false)
    }

    const gettime = (date) => {
        
	    let b=date.split("T")[1]
        return b.slice(0,8)
    }

    const {
        value: reply,
        isValid: enterreplyIsValid,
        hasError: replyInputHasError,
        valueChangeHandler: handlereply,
        inputBlurHandler: handlereplyBlur,
        reset: resetreply
    } = useInput(value => value.trim() !== "")

    useEffect(() => {
        const getToken = JSON.parse(localStorage.getItem('token'));
        if (getToken) {
            
            setToken(pre => pre = getToken);
        }

        fetch("https://api.pishroopardakht.com/api/support/getTicketChats", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${getToken}`
            },
            body: JSON.stringify({
                ticketId: userId
            })
        }).
            then(async (res) => {
                console.log(res.status)
                if (res.status < 300) {
                    res = await res.json();

                    // console.log(res.tickets)

                    setChat([...res])


                } else {
                    res = await res.json();
                    console.log(res)
                }
            }).catch(err => console.log(err))


    }, [click , userId])

    const handleFile = (e) => {
        console.log(e.target.files[0].name)
        setFile(e.target.files[0])
    }

    // a = new Date()
    // console.log(a.toLocaleDateString("fa-IR" , option))

    const option = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    }

    let data = new FormData()

    const replyHandler = async () => {
        data.append("file", file)
        data.append("from", "admin")
        data.append("ticketId", userId)
        data.append("content", reply)
        console.log(file)
        
        // fetch("https://api.pishroopardakht.com/api/support/addReplyChat", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization": `Bearer ${token}`
        //     },
        //     body: JSON.stringify({
        //         file,
        //         content: reply,
        //         from: "admin",
        //         ticketId: userId


        //     })
        // }).
        //     then(async (res) => {
        //         console.log(res.status)
        //         if (res.status < 300) {
        //             res = await res.json();
        //             console.log(res)
        //             toast.success(res.message, {
        //                 position: "top-left",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //             });
        //             setClick(p => p + 1)



        //         } else {
        //             res = await res.json();
        //             console.log(res)
        //             toast.error(res.message, {
        //                 position: "top-left",
        //                 autoClose: 5000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //             });
        //         }
        //     }).catch(err => console.log(err))
        Axios(
            {
              method: "POST",
              url: `https://api.pishroopardakht.com/api/support/addReplyChat`,
              data,
              headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
              }
            }
      
          )
            .then(res => {
              if (res.status < 300) {

                
                toast.success(res.data.message, {
                  position: "top-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setClick(p => p + 1)
                resetreply()
              } else {
                console.log(res)
                toast.error(res.message, {
                  position: "top-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
      
            })
    }
    return (
        <div className='w-100 d-flex justify-content-center align-items-center'>
            
            {show && <ModalPic src={img} show={show} closed={closeModal} />}

            <div className='CHAT position-relative rounded d-flex flex-column justify-content-center align-items-center'>
                <div className='ShowChat w-100  shadow position-relative'>

                    <Row className='w-100'>
                        {chat.map((i) =>

                            <Col className={i.from == "user" ? "w-100 position-relative mx-2 p-2 d-flex flex-column justify-content-center align-items-end" : "w-100 position-relative mx-2 p-2 d-flex flex-column justify-content-center align-items-start"} xs={12} sm={12} md={12} lg={12} xl={12} >



                                {/* {i.from == "user" ?
                                            <div className=' d-flex flex-column userMessage m-2'>
                                                <div className='d-flex align-items-start userMess'>

                                                    <div className='px-2 py-3  userMess-text shadow font-fa fw-500 fs-12'>
                                                        {i.content}

                                                    </div>

                                                    <div style={{ width: "35px", height: "35px", borderRadius: "50%" }} className=' p-2 text-center text-white bg-yellow m-1 fs-15 fw-500'>U</div>
                                                </div>
                                                <div>
                                                    <p className='fs-10 fw-400 text-gray my-2'>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)}</p>
                                                </div>

                                            </div>

                                            :
                                            <div className='d-flex flex-column adminMessage m-2'>
                                                <div className='d-flex align-items-start '>
                                                    <div style={{ width: "35px", height: "35px", borderRadius: "50%" }} className=' p-2 text-center text-white bg-pink m-1 fs-15 fw-500'>A</div>

                                                    <div className='px-2 py-3  adminMess-text shadow font-fa fw-500 fs-12'>
                                                        {i.content}

                                                    </div>

                                                </div>
                                                <div>
                                                    <p className='fs-10 fw-400 text-gray m-2'>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)}</p>
                                                </div>
                                            </div>
                                        } */}

                                {i.from == "user" &&


                                    i.content &&
                                    <div className='userMess d-flex  align-items-end mx-4'>
                                        <div className='d-flex flex-column align-items-end justify-content-start'>

                                            <div className='userMess-text p-2 font-fa bg-yellow text-dark text-end fw-400 fs-12'>
                                                {i.content}
                                            </div>
                                            <p className='fs-10 fw-400 font-fa text-gray m-2'>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)} , {gettime(i.createdAt)}</p>
                                        </div>
                                        <div style={{ width: "35px", height: "35px", borderRadius: "50%" }} className=' p-2 text-center text-white bg-pink my-4 mx-2 fs-15 fw-500'>
                                            U
                                        </div>
                                    </div>

                                }

                                {i.from == "user" &&


                                    i.attachment &&
                                    <div className='userMess d-flex  align-items-end mx-4'>
                                        <div className='d-flex flex-column align-items-end justify-content-start'>

                                            <div>
                                                <img onClick={() => { openModal(); setImg(`http://api.pishroopardakht.com${i.attachment}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.attachment}`} alt='auth' width="60" height="60" />
                                            </div>
                                            <p className='fs-10 fw-400 font-fa text-gray m-2'>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)} , {gettime(i.createdAt)}</p>
                                        </div>
                                        <div style={{ width: "35px", height: "35px", borderRadius: "50%" }} className=' p-2 text-center text-white bg-pink my-4 mx-2 fs-15 fw-500'>
                                            U
                                        </div>
                                    </div>

                                }




                                {i.from == "admin" &&


                                    i.attachment &&
                                    <div className='adminMess d-flex  align-items-end mx-4'>
                                        <div style={{ width: "35px", height: "35px", borderRadius: "50%" }} className=' p-2 text-center text-white bg-pink mx-2 fs-15 fw-500'>
                                            A
                                        </div>
                                        <div className='d-flex flex-column align-items-start justify-content-start'>
                                            <div>
                                                <img onClick={() => { openModal(); setImg(`http://api.pishroopardakht.com${i.attachment}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.attachment}`} alt='auth' width="60" height="60" />
                                            </div>
                                            <p className='fs-10 fw-400 font-fa text-gray m-2'>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)} , {gettime(i.createdAt)}</p>
                                        </div>
                                    </div>

                                }


                                {
                                    i.from == "admin" &&
                                    i.content &&
                                    <div className=' adminMess d-flex align-items-start mx-4 '>
                                        <div style={{ width: "35px", height: "35px", borderRadius: "50%" }} className=' p-2 text-center text-white bg-pink mx-2 fs-15 fw-500'>
                                            A
                                        </div>
                                        <div className='d-flex flex-column align-items-start justify-content-start'>
                                            <div className='adminMess-text p-2 font-fa bg-yellow text-dark text-end fw-400 fs-12'>
                                                {i.content}
                                            </div>
                                            <p className='fs-10 fw-400 font-fa text-gray m-2'>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)} , {gettime(i.createdAt)}</p>
                                        </div>

                                    </div>
                                }



                            </Col>


                        )}
                    </Row>

                </div>
                <div className='w-100 my-2 d-flex justify-content-center align-items-center'>
                    <button onClick={replyHandler} className='rounded-circle border-0 p-2 mx-2 text-white fs-18 fw-700 bg-yellow ' ><IoIosSend /></button>
                    <input value={reply} onChange={handlereply} className='p-2 shadow TYPEMESS w-80 font-fa' type="text" placeholder="نوشتن ..." />
                    <input hidden onChange={handleFile} type="file" id="file" />
                    <label for="file" className=' border-0 p-1 mx-2 text-white fs-18 fw-700 bg-yellow cursor-pointer' style={{ color: 'white', width: "35px", height: "35px", borderRadius: "50%" }}><GrFormUpload /></label>
                    {/* <button onClick={replyHandler} className='rounded-circle border-0 p-2 mx-2 text-white fs-18 fw-700 ' style={{backgroundColor : "#84F5B8"}}></button> */}

                </div>
            </div>
        </div >

    )
}

export default Chat