import React, { useState, useEffect } from 'react'

import './SellOrder.scss'
import { DropdownButton, Dropdown, ButtonGroup, Button } from 'react-bootstrap'
import Search from '../../components/Search/Search'
import SellModal from '../../components/modal/SellModal/SellModal'
import BackDrop from '../../components/BackDrop/BackDrop'
import ModalPic from '../../components/modal/picture/ModalPic'
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import Pagi from '../../components/Pagi/Pagi'
import { AllSell , RejectedSell , ActiveSell } from './components'
import Context from './_context'

function SellOrder() {

  const [token, setToken] = useState("")
  const [total, setTotal] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [show, setShow] = useState(false)
  const [active, setActive] = useState(1)
  const [loading, setLoading] = useState(true)
  const [getSellLi, setSellLi] = useState([])
  const [img, setImg] = useState();
  const [item, setItem] = useState([])

  let a

  const override = css`
  position : absolute;
  top : 10rem;
  left : 45%;
  margin : 20px 0px;

  `;

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem('token'));
    if (getToken) {

      setToken(getToken);
    }

    fetch("http://api.pishroopardakht.com/api/order/getactivesellorders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getToken}`
      },
      body: JSON.stringify({
        limit: 10,
        page: 1
      })
    }).
      then(async (res) => {
        
        if (res.status < 300) {
          res = await res.json();
          
          setTotal(res.totalPages)
          setLoading(false)
          setSellLi(res.orders)

        } else {
          res = await res.json();
          console.log(res)
        }
      }).catch(err => console.log(err))


  },[]);


  const showModalHandler = () => {
    setShowModal(true)
  }

  const closeModalHandler = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShow(true)
  }

  const closeModal = () => {

    setShow(false)
  }

  

  const option = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  }

  var formatter = new Intl.NumberFormat('fa-IR', {
    currency: 'IRR',
    });

    const [page, setPage] = useState("all");

  return (

    <div className="w-100">
            <div className="SELLORDER  position-relative rounded-10 shadow bg-white m-1">
                <div className="w-100 p-1 my-1">
                    <Context.Provider value={[page, setPage]}>
                        {page == "all" && <AllSell />}
                        {page == "active" && <ActiveSell />}
                        {page == "reject" && <RejectedSell />}
                    </Context.Provider>

                </div>
            </div>
        </div>
    // <div className='w-100'>
    //   {showModal && <SellModal item={item} show={showModal} closed={closeModalHandler} />}
    //   {(showModal || show) && <BackDrop show={showModal || show} />}
    //   {show && <ModalPic src={img} show={show} closed={closeModal} />}

    //   <div className="SELLORDER position-relative rounded-10 shadow bg-white m-1">

    //     <div className="my-2 d-flex flex-column align-items-center flex-md-row justify-content-md-between align-items-md-center">
    //       <div className='mx-2 my-2 '>
    //         <button onClick={() => { activeSell() }} className={active == 1 ? 'leftBTN active-order  font-fa fw-700 fs-14 p-2' : 'leftBTN  font-fa fw-700 fs-14 p-2'}>سفارشات فعال</button>
    //         <button onClick={() => { rejectSell() }} className={active == 2 ? 'centerBTN active-order  font-fa fw-700 fs-14 p-2' : 'centerBTN  font-fa fw-700 fs-14 p-2'}>سفارشات تعلیق یافته</button>
    //         <button onClick={() => { allSell() }} className={active == 3 ? 'rightBTN active-order  font-fa fw-700 fs-14 p-2' : 'rightBTN  font-fa fw-700 fs-14 p-2'}>کل سفارشات</button>
    //       </div>
          

          


         
    //     </div>

    //     <div className="w-100 p-1 my-1">
    //       <div className="sellOrderList">
    //         {loading &&
    //           <div>
    //             <PulseLoader color="#F0B31D" loading={loading} css={override} size={25} />
    //           </div>
    //         }
    //         {active == 1 &&
              
    //         }

    //         {active == 2 &&
    //           <table className="w-100 ">
    //             <thead className='bg-white'>
    //               <tr className='font-fa bg-white'>
    //                 <th className='mx-2'>#</th>
    //                 <th>سفارش دهنده</th>
    //                 <th>سفارش براي</th>
    //                 <th>مبلغ سفارش <span><svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11" fill="none">
    //                   <path d="M4 11L0 6H8L4 11Z" fill="#6D6E71" fill-opacity="0.41" />
    //                   <path d="M4 0L0 5H8L4 0Z" fill="#6D6E71" fill-opacity="0.41" />
    //                 </svg></span> </th>
    //                 <th>توضيحات</th>
    //                 <th>txid</th>
                    
    //                 <th>قیمت ارز</th>
    //                 {/* <th>فيش واريزي ما</th> */}
    //                 <th>زمان سفارش</th>
    //                 <th>وضعيت</th>
    //                 <th>ويرايش</th>

    //               </tr>
    //             </thead>
    //             <tbody>
    //               {!loading && getSellLi && getSellLi.map((i , index) =>
    //                 <tr key={i._id} className='font-fa'>
    //                   <td>{index + 1}</td>
    //                   <td className="fs-12 text-tableTd">{ i.user && i.user.name}</td>
    //                   <td className="fs-12 text-tableTd">{ i.coin && i.coin.symbol} {i.coinAmount}</td>
    //                   <td className="fs-12 text-tableTd">{formatter.format(i.totalPayable)} تومان</td>
    //                   <td className="fs-12 text-tableTd">{i.userDecription}</td>
    //                   <td className="fs-10 text-tableTd">{i.txid}</td>
                      
    //                   <td className="fs-10 text-tableTd">{formatter.format(i.coinPrice )} تومان</td>
    //                   {/* <td className="fs-12 text-tableTd"><img onClick={() => { openModal(); setImg(`http://api.pishroopardakht.com${i.image}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.image}`} alt='attampt' width="60" height="60" /></td> */}
    //                   <td>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)}</td>
    //                   <td className="fs-12 text-tableTd">{i.receiveStatus == 0 && <div className='waitSup fw-400 fs-12 text-center font-fa py-1'>در انتظار بررسی</div>}
    //                     {i.receiveStatus == 1 && <div className='accSup fw-400 fs-12 text-center font-fa py-1'>دریافت شد</div>}
    //                     {i.receiveStatus == 2 && <div className='rejSup fw-400 fs-12 text-center font-fa py-1'>دریافت نشد</div>}
    //                   </td>
    //                   <td className='d-flex justify-content-center align-items-center'>
    //                     <span className='my-3 cursor-pointer' onClick={() => { showModalHandler(); setItem(i) }}>
    //                       <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    //                         <path d="M1.5034 9.31636L0 13.9999L4.68367 12.4735L13.9354 3.26091L10.764 0.0900879L1.5034 9.31636Z" fill="#F0B31D" />
    //                       </svg>
    //                     </span>
    //                   </td>

    //                 </tr>
    //               )}
    //             </tbody>
    //           </table>
    //         }

    //         {active == 3 &&
              
    //         }

    //       </div>
    //     </div>

    //   </div>
    //   <div className="w-100 my-3  d-flex flex-column align-items-center flex-md-row justify-content-md-around align-items-md-center">
    //     <Pagi activePage={page} setActivePag={setPage} totalPage={total} />


    //     <div>
    //       <p className='d-inline-block mx-1 fw-400 fs-10'>496/706</p>
    //       <p className='d-inline-block mx-3 fw-400 fs-10'>:ACTIVE CUSTOMERS </p>
    //     </div>
    //   </div>
    // </div>

  )
}

export default SellOrder