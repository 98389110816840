import React , {useEffect} from 'react'

import {  useNavigate , Route, Routes } from 'react-router-dom';

const Main = () => {
    const navigate = useNavigate()
    useEffect(()=>{
        navigate('/login')
    })
  return (
    <div>
    

    </div>
  )
}

export default Main