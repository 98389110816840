import { useState, useRef, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { useSelector, useDispatch } from 'react-redux'
import BackDrop from '../components/BackDrop/BackDrop'
import useWindowDimensions from '../hooks/useWindowDimensions '
import { Row, Col } from 'react-bootstrap'
import './index.scss'

const Layout = () => {
  const { height, width } = useWindowDimensions();
  const [show, setShow] = useState(false)
  const sidebar = useRef()
  const handleChangeShow = () => {
    sidebar.current.classList.toggle("active", show);
  };
  const handleChangeLocation = () => {
    setShow(false);
  };
  useEffect(handleChangeShow, [show]);
  return (
    // <div style={{overflowX:"hidden"}} className='w-100 p-1'>
    //     <div className='HEADER w-100'>
    //         <Header/>
    //     </div>
    //     <div className="BODY d-flex">
    //         <div id="side" className={showSide ? "side p-1 d-none" : "side p-1 d-block"}>

    //             <Sidebar/>
    //         </div>
    //         <div className='w-100' id="content">
    //             { width<900 && !showSide && <BackDrop/>}
    //             <Outlet/>
    //         </div>
    //     </div>
    // </div>
    // <div className="w-100 d-flex justify-content-center align-items-center">
    <div style={{ overflowX: "hidden" }} className="AdminPanel position-relative   w-100 p-2">
      
      <div className="w-100 d-flex justify-content-center align-items-center">
        <Header onHide={setShow} />
      </div>

      <Row className="d-flex AdminPanel-body">
        <Col
          xs="12"
          lg="3"
          xl="2"
          ref={sidebar}
          className="sidebar d-print-none d-flex justify-content-center  "
        >
          <div className=" w-100">
            <Sidebar />
          </div>

          <button
            className="hide-btn flex-1 d-block d-lg-none opacity-0"
            onClick={() => setShow(false)}
          />
        </Col>
        <Col xs="12" lg="9" xl="10" className=" d-flex justify-content-center position-relative bodyy">
          <div className="my-1 w-100">
            <Outlet />
          </div>
        </Col>
      </Row>

    </div>

  )
}

export default Layout