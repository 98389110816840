import React, { useState } from 'react'

import './AddDepartment.scss'
import { Row, Col } from "react-bootstrap"
import Input from '../../../components/Input'
import Btn from '../../../components/settingg/BTN/Btn'
import {axios} from '../../../boot'
import Toast from '../../../methods/_toast'
function AddDepartment() {
  const [data, setData] = useState({})

  const formControls = [
    {
      label: "دپارتمان",
      state: "name"
    }
  ]

  const handleDepart = () => {
    const url = "support/addDepartment"
    axios.post(url, data).then(({ data }) => {
      Toast({text : data.message})
      setData({})
    })
    
  }


  return (
    <div className='w-100 ADD-DEPARTMENT d-flex justify-content-center align-items-start'>
      <Row className='w-100 d-flex justify-content-center align-items-center '>
        <Col className="d-flex justify-content-center align-items-center my-4 " xs={12} sm={12} md={12} lg={5} xl={5} >
          <div className=' w-100 p-4 addDepartment-div shadow mx-1 my-1'>
            <div clasName='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>دپارتمان</p>
            </div>
            <Row className="mx-1 my-5">
              {formControls.map((e) => (
                <Col
                  key={e.state}
                  xs="12"
                  md={12}
                  lg={12}
                >
                  {React.createElement(e.tag ?? Input, {
                    ...e,
                    value: data[e.state],
                    setValue: (val) => setData((p) => ({ ...p, [e.state]: val }))
                  })}
                </Col>
              ))}
              <Col className="d-flex justify-content-center my-4" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn type="button" disabled={!data.name} onClick={handleDepart} title="ثبت دپارتمان" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AddDepartment