import React, { useContext, useEffect, useState } from "react";
import Pagi from "../../../components/Pagi/Pagi";
import Context from "../_context";
import Search from "../../../components/Search/Search";
import ModalPic from "../../../components/modal/picture/ModalPic";
import BackDrop from "../../../components/BackDrop/BackDrop";
import Modal from "../../../components/NewModal";
import './Allpay.scss'
import useEditInput from "../../../hooks/useEditInput";
import {axios} from "../../../boot";
import Toast from "../../../methods/_toast";

const Offlinepay = (props) => {
    const [page, setPage] = useContext(Context);
    const [ id , setId ] = useState(null)
    const [allPay, setAllPay] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState("")
    const [img, setImg] = useState(null)
    const description = useEditInput("")

    const option = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    }

    const getallOfflinePay = () =>{
        const url = "pay/getallOfflinePay"
        const body = {
            limit: 10,
            page: currentPage
        }
        axios.post(url,body).then(({data})=>{
            setTotal(data.totalPages)
            setAllPay([...data.pays])
        })
    }

    useEffect(getallOfflinePay, [currentPage, count]);

    const handleStatusPay = (enterId, status) => {
        const url = "pay/statusPay"
        const body = {
            id: enterId,
            status: status,
            adminDecription: description.value
        }
        axios.post(url,body).then(({data})=>{
            setCount(p => p + 1)
            description.reset()
            Toast({text : data.message})
        })
    }

    var formatter = new Intl.NumberFormat('fa-IR', {
        currency: 'IRR',
    });

    const searchUser = () => {
        const url = "pay/searchOfflinePay"
        const body = {
            search: search.trim()
        }
        axios.post(url , body).then(({data})=>{
            if (data.orders.length == 0 && search) {
                Toast({text : data.message , type : "error"})
            } else if (data.orders.length == 0) {
                setCount(p => p + 1)
            } else {
                setAllPay([...data.orders])
                setSearch("")
            }
        })
    }
    return (
        <div className="w-100">
            {img != null && <ModalPic src={img} show={img != null} closed={()=>setImg(null)} />}
            {img != null && <BackDrop show={img != null} />}

            <Modal
                show={id != null}
                onHide={()=>{setId(null)}}
                variant="white"
                title="دلیل خود را برای رد کردن بنویسید"
                closeBtn
                className=""
                titleClassName="border-0"
            >

                <div className="w-100 p-4">
                    <textarea value={description.value} onChange={description.onChange} className="rounded w-100 p-2" cols={20} rows={5} ></textarea>
                    <button onClick={() => { handleStatusPay(id, 2) }} className='btn btn-outline-danger font-fa fw-700 fs-14 my-2'>رد پرداخت</button>

                </div>


            </Modal>
            <div className="ALLPAY position-relative rounded-10 bg-white ">
                <div className="my-2 d-flex flex-column align-items-center flex-md-row justify-content-md-between align-items-md-center">
                    <div className="my-2 d-flex flex-column align-items-center flex-md-row justify-content-md-center align-items-md-center">
                        <div className='mx-2 my-2 '>
                            <button onClick={() => { setPage("all") }} className={page == "all" ? 'leftBTN pay-order  font-fa fw-700 fs-14 p-2' : 'leftBTN  font-fa fw-700 fs-14 p-2'}>تمام پرداخت</button>
                            <button onClick={() => { setPage("online") }} className={page == "online" ? 'centerBTN pay-order  font-fa fw-700 fs-14 p-2' : 'centerBTN  font-fa fw-700 fs-14 p-2'}>پرداخت آنلاین</button>
                            <button onClick={() => { setPage("offline") }} className={page == "offline" ? 'rightBTN pay-order  font-fa fw-700 fs-14 p-2' : 'rightBTN  font-fa fw-700 fs-14 p-2'}>پرداخت آفلاین</button>
                        </div>
                    </div>
                    <div className="mx-4 d-flex  align-items-center">
                        {/* <span className='cursor-pointer mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z" fill="#F0B31D" />
                                <path d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z" fill="#F0B31D" />
                                <path d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z" fill="#F0B31D" />
                            </svg>
                        </span> */}
                        <Search value={search} onChange={(e) => { setSearch(e.target.value) }} onClick={searchUser} placeholder='جستجو نام, شماره , شناسه سفارش ...' />
                    </div>
                </div>

                <div className="w-100 p-1 my-1">
                    <div className="allList">
                        <table className="w-100 ">
                            <thead className='bg-white'>
                                <tr className='font-fa bg-white'>
                                    <th className='mx-2'>#</th>
                                    <th>نام و نام خانوادگی</th>
                                    <th>شماره تلفن</th>
                                    {/* <th>مبلغ سفارش <span><svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11" fill="none">
                                        <path d="M4 11L0 6H8L4 11Z" fill="#6D6E71" fill-opacity="0.41" />
                                        <path d="M4 0L0 5H8L4 0Z" fill="#6D6E71" fill-opacity="0.41" />
                                    </svg></span> </th> */}
                                    <th>مبلغ</th>
                                    <th>شناسه سفارش</th>
                                    <th>عکس پرداخت</th>
                                    <th>نوع پرداخت</th>
                                    <th>وضعيت</th>
                                    <th>زمان سفارش</th>
                                    <th>تایید پرداخت</th>
                                    {/* <th>ويرايش</th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {allPay && allPay.map((i, index) =>
                                    <tr key={i._id} className='font-fa'>
                                        <td className="fs-12 text-tableTd">{index + 1}</td>
                                        <td className="fs-12 text-tableTd">{i.user && i.user.name}</td>
                                        <td className="fs-12 text-tableTd">{i.user && i.user.phone} </td>
                                        <td className="fs-12 text-tableTd">{formatter.format(i.amount)} تومان </td>
                                        <td className="fs-10 text-tableTd">{i.refNumber}</td>
                                        <td className="fs-12 text-white p-2"><img onClick={() => {setImg(`http://api.pishroopardakht.com${i.image}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.image}`} alt='meli' width="60" height="60" /></td>
                                        <td className="fs-12 text-tableTd">
                                            {i.type == 1 && <p className="fs-12  fw-400  my-1 font-fa">آفلاین</p>}
                                            {i.type == 2 && <p className="fs-12 text-success fw-400  my-1 font-fa">آنلاین</p>}
                                        </td>
                                        <td className="fs-12 text-tableTd ">
                                            {i.status == 0 && <div className='waitSup fw-400 fs-12 text-center font-fa py-1 mx-auto'>در انتظار بررسی</div>}
                                            {i.status == 1 && <div className='accSup fw-400 fs-12 text-center font-fa py-1 mx-auto'>تایید شد</div>}
                                            {i.status == 2 && <div className='rejSup fw-400 fs-12 text-center font-fa py-1 mx-auto'>رد شد</div>}
                                        </td>

                                        <td>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)}</td>
                                        <td className="fs-12 text-tableTd ">
                                            <button onClick={() => { setId(p=>p=i._id) }} className='rejAuth font-fa fw-700 fs-14 mx-1 my-2'>رد</button>
                                            <button onClick={() => { handleStatusPay(i._id, 1) }} className='accAuth font-fa fw-700 fs-14 my-2'>تایید</button>
                                        </td>
                                        {/* <td className='d-flex justify-content-center align-items-center'>
                                            <span className='my-3 cursor-pointer  ' onClick={() => { }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                    <path d="M1.5034 9.31636L0 13.9999L4.68367 12.4735L13.9354 3.26091L10.764 0.0900879L1.5034 9.31636Z" fill="#F0B31D" />
                                                </svg>
                                            </span>
                                        </td> */}

                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            <div className="w-100 my-3  d-flex flex-column align-items-center flex-md-row justify-content-md-around align-items-md-center">
                <Pagi activePage={currentPage} setActivePag={setCurrentPage} totalPage={total} />
                <div>
                    <p className='d-inline-block mx-1 fw-400 fs-10'>496/706</p>
                    <p className='d-inline-block mx-3 fw-400 fs-10'>:ACTIVE CUSTOMERS </p>
                </div>
            </div>
        </div>
    )
}

export default Offlinepay