import React, { useState, useEffect } from 'react'

import './BankCards.scss'
import Search from '../../components/Search/Search'
import ModalPic from '../../components/modal/picture/ModalPic'
import Modal from '../../components/NewModal'
import Form from '../../components/Form'
import Input from '../../components/Input'
import Toast from '../../methods/_toast'
import { Button , Col , Row } from 'react-bootstrap'
import cloneDeep from "lodash/cloneDeep";
import { css } from "@emotion/react";
import Pagi from '../../components/Pagi/Pagi'
import axios from '../../boot/axios'
import BankCardModal from '../../components/modal/BankCardModals/BankCardModal'



function BankCards() {
    const [card, setCard] = useState([])
    const [show, setShow] = useState(false)
    const [img, setImg] = useState();
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [show2, setShow2] = useState(false)
    const [data, setData] = useState({})




    const override = css`
    position : absolute;
    top : 10rem;
    left : 45%;
    margin : 20px 0px;

    `;





    const getBankCard = () => {
        const url = "creditcards/getAllcards"
        const body = {
            limit: 10,
            page
        };
        axios.post(url, body).then(({ data }) => {
            setTotal(data.totalPages)
            setCard([...data.cards])
        });
    }

    useEffect(getBankCard, [page])


    const searchCards = () => {
        const url = "creditcards/searchCards"
        const body = {
            search: search.trim()
        }
        axios.post(url, body).then(({ data }) => {
            setCard([...data.cards])
            setSearch("")
            if (data.cards?.length == 1) {
                Toast({ type: "error", text: "کاربر یافت نشد" })
            }
        })
    }





    const option = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    }

    const accBackCard = (a) => {
        const url = "creditcards/acceptCard"
        const body = cloneDeep(data);
        body.status = a
        axios.post(url, body).then(({ data }) => {
            Toast({ text: data.message })
            setData({})
        })
    }

    const formControls = [

        {
            as: "textarea",
            label: "توضیحات",
            state: "description",
        },
    ];


    return (
        <div>
            <div className="BANKCARDS position-relative rounded-10 shadow bg-white m-1">
                <Modal
                    show={show2}
                    onHide={setShow2}
                    title="ویرایش کارت بانکی"
                    size='md'
                >
                    <Row className="p-2">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form className="row" >
                            {formControls.map((e) => (
                                <Col
                                    key={e.state}
                                    xs="12"
                                    md={e.state === "description" ? "12" : "6"}
                                    lg={e.state === "description" ? "12" : "4"}
                                >
                                    {React.createElement(e.tag ?? Input, {
                                        ...e,
                                        value: data[e.state],
                                        setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                                    })}
                                </Col>
                            ))}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button  onClick={() => accBackCard( 1)} className='fw-500 fs-14 font-fa mx-1 px-4' variant='outline-success'>تایید</Button>
                                <Button  onClick={() => accBackCard(2)} className='fw-500 fs-14 font-fa mx-1 px-4' variant='outline-danger'>رد</Button>
                            </Col>
                        </Form>
                        </Col>
                        
                    </Row>

                </Modal>

                <Modal
                    show={show}
                    onHide={setShow}
                    size='md'
                >

                </Modal>

                {/* {show && <ModalPic src={img} show={show} closed={closeModal} />} */}
                <div className="my-3 mx-4 d-flex flex-column align-items-center flex-md-row justify-content-md-end align-items-md-center">

                    <div className="mx-2  d-flex  align-items-center">
                        {/* <span className='cursor-pointer mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z" fill="#F0B31D" />
                                <path d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z" fill="#F0B31D" />
                                <path d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z" fill="#F0B31D" />
                            </svg>
                        </span> */}
                        <Search value={search} onChange={(e) => { setSearch(e.target.value) }} onClick={searchCards} placeholder='جستجو كنيد ...' />


                    </div>
                </div>

                <div className="w-100 p-1 my-1">
                    <div className="BANKCARDSList">

                        <table className="w-100 ">
                            <thead className='bg-white'>
                                <tr className='font-fa bg-white'>
                                    <th className='mx-2'>#</th>
                                    <th className=''>نام بانک</th>
                                    <th className=''>شماره حساب</th>
                                    <th className=''>شماره کارت</th>
                                    <th className=''>شماره شبا</th>
                                    <th className=''>عکس کارت بانکی</th>
                                    <th className=''>تاریخ</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {card && card.map((i, index) =>
                                    i.status == 1 ?
                                        <tr style={{ backgroundColor: "#54ECC4" }} key={i._id} className='font-fa'>
                                            <td className="fs-12 text-white p-2">{index + 1}</td>
                                            <td className="fs-12 text-white p-2">{i.bankName}</td>
                                            <td className="fs-12 text-white p-2">{i.hesabNumber}</td>
                                            <td className="fs-12 text-white p-2">{i.cardNumber}</td>
                                            <td className="fs-12 text-white p-2">{i.shebaNumber}</td>
                                            <td className="fs-12 text-white p-2"><img onClick={() => { setShow(true); setImg(`http://api.pishroopardakht.com${i.bankCardImage}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.bankCardImage}`} alt='meli' width="60" height="60" /></td>
                                            <td className="fs-12 text-white">{new Date(i.updatedAt).toLocaleDateString("fa-IR", option)}</td>
                                            <td className=''>
                                                {/* <button onClick={() => { accAuth(i.phone, 2) }} className='rejAuth font-fa fw-700 fs-14 mx-1 my-2'>رد کردن</button> */}
                                                <button onClick={() => { setShow2(true); setData((p) => ({ ...p, id: i._id })) }} className='accRej   font-fa fw-600 fs-14 my-2'>تایید / رد</button>
                                            </td>

                                        </tr>
                                        :
                                        <tr key={i._id} className='font-fa'>
                                            <td className="fs-12 text-tableTd p-2">{index + 1}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.bankName}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.hesabNumber}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.cardNumber}</td>
                                            <td className="fs-12 text-tableTd p-2">{i.shebaNumber}</td>
                                            <td className="fs-12 text-tableTd p-2"><img onClick={() => { setShow(true); setImg(`http://api.pishroopardakht.com${i.bankCardImage}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.bankCardImage}`} alt='meli' width="60" height="60" /></td>
                                            <td className="fs-12 text-tableTd">{new Date(i.updatedAt).toLocaleDateString("fa-IR", option)}</td>
                                            <td className=''>
                                                {/* <button onClick={() => { accAuth(i.phone, 2) }} className='rejAuth font-fa fw-700 fs-14 mx-1 my-2'>رد کردن</button> */}
                                                <button onClick={() => { setShow2(true); setData((p) => ({ ...p, id: i._id })) }} className='accRej   font-fa fw-600 fs-14 my-2'>تایید / رد</button>
                                            </td>

                                        </tr>

                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            <div className="w-100 my-3  d-flex flex-column align-items-center flex-md-row justify-content-md-around align-items-md-center">
                <Pagi activePage={page} setActivePag={setPage} totalPage={total} />


                <div>
                    <p className='d-inline-block mx-1 fw-400 fs-10'>496/706</p>
                    <p className='d-inline-block mx-3 fw-400 fs-10'>:ACTIVE CUSTOMERS </p>
                </div>
            </div>
        </div>
    )
}

export default BankCards