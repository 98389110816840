import { useEffect, useState } from 'react'

import { css } from "@emotion/react";
import './EditMessage.scss'
import { toast, ToastContainer } from "react-toastify"
import Search from '../../../components/Search/Search';
import Pagi from '../../../components/Pagi/Pagi';
import DeleteModal from '../../../components/modal/deleteModal/DeleteModal';
import { useSelector, useDispatch } from 'react-redux'
import {axios} from '../../../boot';
import Modal from '../../../components/NewModal'





const EditMessage = () => {

    const [message, setMessage] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    // const [search, setSearch] = useState("")
    const [show2, setShow2] = useState(false)
    const [userId, setUserId] = useState("")


    const openModal2 = () => {
        setShow2(true)
    }

    const closeModal2 = () => {
        setShow2(false)
    }

    const override = css`
        position : absolute;
        top : 10rem;
        left : 45%;
        margin : 20px 0px;

        `;

    

    const getMessage = () => {
        const url = "message/getMessage"
        const body = {
            limit: 10,
            page
        }
        axios.post(url, body).then(({ data }) => {
            setTotal(data.totalPages)
            setMessage([...data])
        })
    }

    useEffect(getMessage, [page])

    // const deleteWallet = (enterId) => {
    //     fetch("http://api.pishroopardakht.com/api/wallet/deleteWallet", {
    //         method: "DELETE",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         },
    //         body: JSON.stringify({
    //             id: enterId
    //         })
    //     }).
    //         then(async (res) => {
    //             console.log(res.status)
    //             if (res.status < 300) {
    //                 res = await res.json();
    //                 console.log(res)
    //                 toast.success(res.message, {
    //                     position: "top-left",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });

    //             } else {
    //                 res = await res.json();
    //                 console.log(res)
    //                 toast.error(res.message, {
    //                     position: "top-left",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //             }
    //         }).catch(err => console.log(err))
    // }





    const option = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    }


    // const searchWallet = () => {
    //     fetch("http://api.pishroopardakht.com/api/wallet/searchWallet", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         },
    //         body: JSON.stringify({
    //             search
    //         })
    //     }).
    //         then(async (res) => {
    //             console.log(res.status)
    //             if (res.status < 300) {
    //                 res = await res.json();
    //                 console.log(res.wallet)
    //                 if (search && res.wallet != []) {
    //                     setGetWallet(res.wallet)
    //                 } else if (!search) {
    //                     window.location.reload()
    //                 } else if (res.wallet == [] && search) {
    //                     toast.error("ولت وارد شده پیدا نشد", {
    //                         position: "top-left",
    //                         autoClose: 5000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined,
    //                     });

    //                 }


    //                 setSearch("")


    //             } else {
    //                 res = await res.json();
    //                 console.log(res)


    //             }
    //         }).catch(err => console.log(err))
    // }


    return (
        <div>
            {/* {show2 && <DeleteModal url="http://api.pishroopardakht.com/api/message/deleteMessage" id={userId}  />} */}

            {/* {show2 && <BackDrop show={show2} />} */}

            <Modal
                show={show2}
                onHide={setShow2}
                title="آیا از حذف پیام مطمئن هستید؟"
                type="yellow"
            >
                <div className="w-100 d-flex justify-content-center align-items-center">
                    <DeleteModal onHide={setShow2} url="http://api.pishroopardakht.com/api/message/deleteMessage"  id={userId} />
                </div>

            </Modal>
            
            <div className="EDITMESSAGE position-relative rounded-10 shadow bg-white m-1">
                <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* <div className="my-3 mx-4 d-flex flex-column align-items-center flex-md-row justify-content-md-end align-items-md-center">

                    <div className="mx-2  d-flex  align-items-center">
                        <span style={{ cursor: "pointer" }} className='cursor-pointer mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z" fill="#F0B31D" />
                                <path d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z" fill="#F0B31D" />
                                <path d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z" fill="#F0B31D" />
                            </svg>
                        </span>
                        <Search onClick={searchWallet} value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='جستجو سینبل ...' />
                    </div>
                </div> */}
                <div className="my-3 mx-4 d-flex flex-column align-items-center flex-md-row justify-content-md-end align-items-md-center">

                    {/* <div className="mx-2  d-flex  align-items-center">
                        <span style={{ cursor: "pointer" }} onClick={searchUser} className='cursor-pointer mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z" fill="#F0B31D" />
                                <path d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z" fill="#F0B31D" />
                                <path d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z" fill="#F0B31D" />
                            </svg>
                        </span>
                        <Search value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='جستجو كنيد ...' />
                    </div> */}
                </div>

                <div className="w-100 p-1 my-1">
                    <div className="editMessage">
                        
                        <table className="w-100 ">
                            <thead className='bg-white'>
                                <tr className='font-fa bg-white'>
                                    <th className='mx-2'>#</th>
                                    <th>عنوان</th>
                                    <th>متن پیام</th>
                                    <th>تاریخ</th>
                                    <th></th>

                                </tr>
                            </thead>
                            <tbody>
                                {message && message.map((i, index) =>
                                    <tr key={i._id} className='font-fa'>
                                        <td className="fs-12 text-tableTd">{index + 1}</td>
                                        <td className="fs-12 text-tableTd">{i.title}</td>
                                        {/* <td className=''>
                                            <img src="" alt="receipt" width="60px" height="60px" />
                                        </td> */}
                                        <td className="fs-12 text-tableTd">{i.content}</td>
                                        <td className="fs-12 text-tableTd">{new Date(i.createdAt).toLocaleDateString("fa-IR", option)}</td>
                                        <td className=''>
                                            <span onClick={() => { setUserId(i._id); openModal2() }} className='cursor-pointer'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                                                    <path d="M15.1872 1.47368H10.9091V1.43158C10.9091 0.631579 10.2674 0 9.45455 0H6.54545C5.73262 0 5.09091 0.631579 5.09091 1.43158V1.47368H0.812834C0.363636 1.47368 0 1.83158 0 2.27368V3.07368C0 3.51579 0.363636 3.87368 0.812834 3.87368H15.1872C15.6364 3.87368 16 3.51579 16 3.07368V2.27368C16 1.83158 15.6364 1.47368 15.1872 1.47368Z" fill="#A82070" />
                                                    <path d="M14.1177 4.96851H1.88244C1.64714 4.96851 1.47602 5.15798 1.49741 5.38956L2.09634 17.579C2.16051 18.9264 3.2942 20.0001 4.66319 20.0001H11.3156C12.6846 20.0001 13.8183 18.9475 13.8824 17.579L14.4814 5.38956C14.5241 5.17903 14.3316 4.96851 14.1177 4.96851ZM6.67388 16.7369C6.67388 17.1159 6.37442 17.4106 5.98939 17.4106C5.60436 17.4106 5.3049 17.1159 5.3049 16.7369V8.25272C5.3049 7.87377 5.60436 7.57903 5.98939 7.57903C6.37442 7.57903 6.67388 7.87377 6.67388 8.25272V16.7369ZM10.6953 16.7369C10.6953 17.1159 10.3958 17.4106 10.0108 17.4106C9.62575 17.4106 9.32629 17.1159 9.32629 16.7369V8.25272C9.32629 7.87377 9.62575 7.57903 10.0108 7.57903C10.3958 7.57903 10.6953 7.87377 10.6953 8.25272V16.7369Z" fill="#A82070" />
                                                </svg>
                                            </span>
                                            {/* <span className='my-3 mx-3 cursor-pointer' onClick={() => { openModal(); setItem(i) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                    <path d="M1.5034 9.31636L0 13.9999L4.68367 12.4735L13.9354 3.26091L10.764 0.0900879L1.5034 9.31636Z" fill="#F0B31D" />
                                                </svg>

                                            </span> */}

                                        </td>

                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            <div className="w-100 my-3  d-flex flex-column align-items-center flex-md-row justify-content-md-around align-items-md-center">
                <Pagi activePage={page} setActivePag={setPage} totalPage={total} />
                <div>
                    <p className='d-inline-block mx-1 fw-400 fs-10'>496/706</p>
                    <p className='d-inline-block mx-3 fw-400 fs-10'>:ACTIVE CUSTOMERS </p>
                </div>
            </div>
        </div>
    )
}

export default EditMessage