
import React, { useEffect, useState } from 'react'

import './EditCoin.scss'
import Input from '../../settingg/Input/Input'
import Btn from '../../settingg/BTN/Btn'
import { Col, Row } from 'react-bootstrap'
import { AiFillCloseCircle } from "react-icons/ai"
import { toast, ToastContainer } from "react-toastify"
import useEditInput from '../../../hooks/useEditInput'


const EditCoin = (props) => {
    const cssStyle = ["EDITCOIN", props.show ? "ModalOpen" : "ModalClose"]

    const [token, setToken] = useState("")

    const name = useEditInput(props.item?.name)
    const symbol = useEditInput(props.item?.symbol)
    const prName = useEditInput(props.item?.persianName)


    

    useEffect(() => {

        const getToken = JSON.parse(localStorage.getItem('token'));
        if (getToken) {
            setToken(getToken)
        }
    }, [])


    const editCoin = () => {
        fetch("http://api.pishroopardakht.com/api/coin/editcoin", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify({
                id: props.item._id,
                name : name.value,
                symbol: symbol.value,
                persianName : prName.value

            })
        }).
            then(async (res) => {
                if (res.status < 300) {
                    res = await res.json();
                    toast.success(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                } else {
                    res = await res.json();
                    toast.error(res.message, {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(err => console.log(err))
    }
    return (
        <div className={cssStyle.join(" ")}>
            <button className='fw-700 fs-15' onClick={props.closed} style={{ position: "absolute", top: "10px", right: "10px", border: "none", background: " none" }} > <AiFillCloseCircle /> </button>
            <Row className='d-flex justify-content-start align-items-center my-4'>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={name.value} onChange={name.onChange} title="نام" />
                </Col>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={symbol.value} onChange={symbol.onChange} title="سیمبل" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={prName.value} onChange={prName.onChange} title="نام فارسی" />
                </Col>


                <Col xs={12} md={12} sm={12} lg={12} xl={12} >
                    <Btn onClick={editCoin} title="ذخیره" />
                </Col>
            </Row>
        </div>
    )
}

export default EditCoin