import React , {useState} from 'react'

import { Row, Col } from 'react-bootstrap'
import Alert from '../../components/Alert/Alert'
import Input from '../../components/settingg/Input/Input'
import Btn from '../../components/settingg/BTN/Btn'
import useInput from '../../hooks/useInput'
import './RuleManage.scss'
function RuleManage() {
    const [ result , setResult ] = useState("")
    const [ type , setType ] = useState("")
    const [ showAlert , setShowAlert ] = useState(false)


    const {
        value : Rule,
        isValid : enterRuleIsValid,
        hasError : RuleInputHasError,
        valueChangeHandler :  handleRule,
        inputBlurHandler : handleRuleBlur,
        reset : resetRuleInput
    } = useInput(value => value.trim()!=="")


    const submitRule = async() => {

        try{
              const resAPI = await fetch("http://api.pishroopardakht.com/api/term/addTerm",{
                  method : "POST",
                  headers : {"Content-Type": "application/json"
              },
                  
                  body: JSON.stringify({
                      content : Rule

                  })
      
              })
              
              
              if(resAPI.status < 300){
                const res = await resAPI.json();
                
                
                
                setResult(res.message)
                setShowAlert(true)
                setType("success")
                setTimeout(()=>{
                    setShowAlert(false)
                    setType("")
                    setResult("")
                },4000)
                resetRuleInput()
              }else{
                const res = await resAPI.json();
                console.log(res)
                setResult(res.message)
                setShowAlert(true)
                setType("error")
                setTimeout(()=>{
                    setShowAlert(false)
                    setType("")
                    setResult("")
                },4000)
              }
              
      
          }catch(error){
              
          }
      }
    return (
        <div className='w-100 MANAGE-RULE'>
            {showAlert && <Alert result={result} type={type} />}
            <Row className='w-100 d-flex justify-content-center align-items-center'>
                <Col className="my-4" xs={12} sm={12} md={12} lg={6} xl={6} >
                    <div className=' w-100 p-2 RULE-div shadow mx-1 my-1'>
                        <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
                            <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>مدیریت قوانین و مقررات</p>
                        </div>
                        <Row className="mx-1 my-5">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                                <textarea value={Rule} onChange={handleRule} rows="5" className="w-100"></textarea>
                            </Col>

                            <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Btn onClick={submitRule} title="ثبت قوانین و مقررات" />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default RuleManage