import React, { useState } from 'react'

import { Row, Col } from "react-bootstrap"
import Input from '../../../components/Input'
import Btn from '../../../components/settingg/BTN/Btn'
import './AddAccount.scss'
import {axios} from '../../../boot'
import Toast from '../../../methods/_toast'

const AddAccount = () => {
  const [data, setData] = useState({})

  const formControls = [

    {
      label: "نام صاحب حساب",
      state: "name"
    },
    {
      label: "شماره کارت",
      state: "cardNumber"
    },
    {
      label: "شماره حساب",
      state: "hesabNumber"
    },
    {
      label: "شماره شبا",
      state: "shebaNumber"
    },
    {
      label: "نام بانک",
      state: "bankName"
    },
  ]

  const addAccount = () => {
    const url = "account/addAccount"
    axios.post(url, data).then(({ data }) => {
      Toast({ text: data.message })
      setData({})
    })
  }
  return (
    <div className='w-100 ADD-ACCOUNT d-flex justify-content-center align-items-center '>
      <Row className='w-100 d-flex justify-content-center align-items-center'>
        <Col className="d-flex justify-content-center align-items-center my-4" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-4 addAccount-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>اضافه کردن حساب</p>
            </div>
            <Row className="mx-1 my-5">
              {formControls.map((e) => (
                <Col
                  key={e.state}
                  xs="12"
                  md={6}
                  lg={6}
                  className=""
                >
                  {React.createElement(e.tag ?? Input, {
                    ...e,
                    value: data[e.state],
                    setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                  })}
                </Col>
              ))}
              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn disabled={!data.cardNumber || !data.name || !data.shebaNumber || !data.bankName || !data.hesabNumber} onClick={addAccount} title="ثبت اطلاعات حساب " />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AddAccount