import React from 'react'

import './Alert.scss'
import { FcOk , FcCancel } from "react-icons/fc";

function Alert({
    result,
    type
}) {
  return (
    <div className='ALERT w-100'>
        {type == "success" &&
            <div className='ALERT-suc  shadow p-2 d-flex justify-content-start align-items-center'>
                <div className='fs-30'><FcOk/></div>
                <div>
                    <p className='font-fa fw-700 fs-18 text-center mt-4 mx-3'>{result}</p>
                </div>
                
            </div>
        }
        {type == "error" &&
            <div className='ALERT-err shadow p-2 d-flex justify-content-start align-items-center'>
                <div className='fs-30'><FcCancel/></div>
                <div>
                    <p className='font-fa fw-700 fs-18 text-center text-white mt-4 mx-3'>{result}</p>
                </div>
                
            </div>
        }
        
    </div>
  )
}

export default Alert