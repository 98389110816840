import React, { useState, useEffect, useContext } from 'react'

import './AllSell.scss'
import Pagi from '../../../components/Pagi/Pagi';
import Search from '../../../components/Search/Search';
import BackDrop from '../../../components/BackDrop/BackDrop';
import SellModal from '../../../components/modal/SellModal/SellModal'
import Context from '../_context';
import ModalPic from '../../../components/modal/picture/ModalPic';
import Modal from '../../../components/NewModal';
import { axios } from '../../../boot'
import Toast from '../../../methods/_toast';

const ActiveSell = () => {
  const [page, setPage] = useContext(Context);
  const [all, setAll] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState("")
  const [img, setImg] = useState(null)

  // const openModal = () => {
  //   setShow(true)
  // }

  // const closeModal = () => {

  //   setShow(false)
  // }

  const option = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  }

  var formatter = new Intl.NumberFormat('fa-IR', {
    currency: 'IRR',
  });

  const getactivesellorders = () => {
    const url = "order/getactivesellorders"
    const body = {
      limit: 10,
      page: currentPage
    }
    axios.post(url, body).then(({ data }) => {
      console.log(data)
      setTotal(data.totalPages)
      setAll(data.orders)
    })
  }

  useEffect(getactivesellorders, [currentPage]);

  const showModalHandler = () => {
    setShowModal(true)
  }

  const closeModalHandler = () => {
    setShowModal(false)
  }

  const searchUser = () => {
    const url = "order/searchActiveSellOrder"
    const body = {
      search: search.trim()
    }
    axios.post(url, body).then(({ data }) => {
      if (data.orders.length == 0 && search) {
        Toast({ text: "کاربر مورد نظر یافت نشد", type: "error" })
      } else if (data.orders.length == 0) {
        // setCount(p => p + 1)
      } else {
        setAll([...data.orders])
        setSearch("")
      }
    })
  }

  return (
    <div className='w-100'>
      {showModal && <SellModal item={item} show={showModal} closed={closeModalHandler} />}
      {showModal && <BackDrop show={showModal} />}
      {/* {show && <ModalPic src={img} show={show} closed={closeModal} />} */}

      <Modal
        
        show={img !== null}
        onHide={() => setImg(null)}
      >
        <div className='w-100 d-flex justify-content-center align-items-center'>
          <img className='IMGreceipt' width="100%" height="100%" src={img} alt="receipt" />
        </div>
      </Modal>

      <div className="ALLORDER position-relative rounded-10 bg-white">
        <div className="my-2 d-flex flex-column align-items-center flex-md-row justify-content-md-between align-items-md-center">
          <div className='mx-2 my-2 '>
            <button onClick={() => { setPage("active") }} className={page == "active" ? 'leftBTN all-order  font-fa fw-700 fs-14 p-2' : 'leftBTN  font-fa fw-700 fs-14 p-2'}>سفارشات فعال</button>
            <button onClick={() => { setPage("reject") }} className={page == "reject" ? 'centerBTN all-order  font-fa fw-700 fs-14 p-2' : 'centerBTN  font-fa fw-700 fs-14 p-2'}>سفارشات تعلیق یافته</button>
            <button onClick={() => { setPage("all") }} className={page == "all" ? 'rightBTN all-order  font-fa fw-700 fs-14 p-2' : 'rightBTN  font-fa fw-700 fs-14 p-2'}>کل سفارشات</button>
          </div>

          <div className="mx-2  d-flex  align-items-center">
            {/* <span style={{ cursor: "pointer" }} onClick={searchUser} className='cursor-pointer mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M14 18.5C14 17.6716 13.3284 17 12.5 17H7.5C6.67157 17 6 17.6716 6 18.5C6 19.3284 6.67157 20 7.5 20H12.5C13.3284 20 14 19.3284 14 18.5Z" fill="#F0B31D" />
                                <path d="M17 11C17 9.89543 16.1046 9 15 9H5C3.89543 9 3 9.89543 3 11C3 12.1046 3.89543 13 5 13H15C16.1046 13 17 12.1046 17 11Z" fill="#F0B31D" />
                                <path d="M0 2.5C0 3.88071 1.11929 5 2.5 5H17.5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0H2.5C1.11929 0 0 1.11929 0 2.5Z" fill="#F0B31D" />
                            </svg>
                        </span> */}
            <Search value={search} onChange={(e) => { setSearch(e.target.value) }} onClick={searchUser} placeholder='جستجو شماره و نام ...' />
          </div>


        </div>

        <div className="w-100 p-1 my-1">
          <div className="allOrderList">
            <table className="w-100 ">
              <thead className='bg-white'>
                <tr className='font-fa bg-white'>
                  <th className='mx-2'>#</th>
                  <th>سفارش دهنده</th>
                  <th>شماره تلفن</th>
                  <th>سفارش براي</th>
                  <th>مبلغ سفارش <span><svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11" fill="none">
                    <path d="M4 11L0 6H8L4 11Z" fill="#6D6E71" fill-opacity="0.41" />
                    <path d="M4 0L0 5H8L4 0Z" fill="#6D6E71" fill-opacity="0.41" />
                  </svg></span> </th>
                  <th>توضيحات</th>
                  <th>تسویه</th>
                  <th>txid</th>
                  <th>عکس سفارش</th>
                  <th>قیمت ارز</th>
                  {/* <th>فيش واريزي ما</th> */}
                  <th>زمان سفارش</th>
                  <th>وضعيت</th>
                  <th>ويرايش</th>

                </tr>
              </thead>
              <tbody>
                {all && all.map((i, index) =>
                  <tr key={i._id} className='font-fa'>
                    <td className="fs-12 text-tableTd">{index + 1}</td>
                    <td className="fs-12 text-tableTd">{i.user && i.user.name}</td>
                    <td className="fs-12 text-tableTd">{i.user && i.user.phone}</td>
                    <td className="fs-12 text-tableTd">{i.coin && i.coin.symbol} {i.coinAmount}</td>
                    <td className="fs-12 text-tableTd">{formatter.format(i.totalPayable)} تومان</td>
                    <td className="fs-12 text-tableTd">{i.userDecription}</td>
                    <td className="fs-12 text-tableTd">
                      {i.payStatus == false && <div className='rejSup mx-auto fw-400 fs-12 text-center font-fa py-1'>تسویه نشده</div>}
                      {i.payStatus == true && <div className='accSup mx-auto fw-400 fs-12 text-center font-fa py-1'>تسویه شد</div>}
                    </td>
                    <td className="fs-10 text-tableTd">{i.txid}</td>
                    <td className="fs-12 text-white p-2"><img onClick={() => {  setImg(`http://api.pishroopardakht.com${i.image}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.image}`} alt='meli' width="60" height="60" /></td>
                    <td className="fs-10 text-tableTd">{i.coinPrice}</td>
                    {/* <td className="fs-12 text-tableTd"><img onClick={() => { openModal(); setImg(`http://api.pishroopardakht.com${i.image}`) }} style={{ cursor: "zoom-in" }} src={`http://api.pishroopardakht.com${i.image}`} alt='attampt' width="60" height="60" /></td> */}
                    <td>{new Date(i.createdAt).toLocaleDateString("fa-IR", option)}</td>
                    <td className="fs-12 text-tableTd">{i.receiveStatus == 0 && <div className='waitSup mx-auto fw-400 fs-12 text-center font-fa py-1'>در انتظار بررسی</div>}
                      {i.receiveStatus == 1 && <div className='accSup mx-auto fw-400 fs-12 text-center font-fa py-1'>دریافت شد</div>}
                      {i.receiveStatus == 2 && <div className='rejSup mx-auto fw-400 fs-12 text-center font-fa py-1'>دریافت نشد</div>}
                    </td>
                    <td className='d-flex justify-content-center align-items-center'>
                      <span className='my-3 cursor-pointer' onClick={() => { showModalHandler(); setItem(i) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                          <path d="M1.5034 9.31636L0 13.9999L4.68367 12.4735L13.9354 3.26091L10.764 0.0900879L1.5034 9.31636Z" fill="#F0B31D" />
                        </svg>
                      </span>
                    </td>

                  </tr>
                )}
              </tbody>
            </table>

          </div>
        </div>

      </div>
      <div className="w-100 my-3  d-flex flex-column align-items-center flex-md-row justify-content-md-around align-items-md-center">

        <Pagi activePage={currentPage} setActivePag={setCurrentPage} totalPage={total} />


        <div>
          <p className='d-inline-block mx-1 fw-400 fs-10'>496/706</p>
          <p className='d-inline-block mx-3 fw-400 fs-10'>:ACTIVE CUSTOMERS </p>
        </div>
      </div>
    </div>
  )
}

export default ActiveSell