import React, { useEffect, useState } from 'react'


import Input from '../../settingg/Input/Input'
import Btn from '../../settingg/BTN/Btn'
import { Col, Row, Form , Dropdown } from 'react-bootstrap'
import useEditInput from '../../../hooks/useEditInput'
import Toast from '../../../methods/_toast'


const UserListModal = (props) => {


    const [token, setToken] = useState("")
    const [accEmail, setAccEmail] = useState(props.item.emailActivate)
    const [ status , setStatus ] = useState(props.item.authentication)

    const name = useEditInput(props.item.name ? props.item.name : "")
    const phone = useEditInput(props.item.phone)
    
    const email = useEditInput(props.item.email)
    const codeMeli = useEditInput(props.item.codeMelli)
    const phoneSabet = useEditInput(props.item.phoneSabet)
    const address = useEditInput(props.item.address)
    const score = useEditInput(props.item.score)
    const credit = useEditInput(props.item.credit)


    useEffect(() => {

        const getToken = JSON.parse(localStorage.getItem('token'));
        if (getToken) {
            setToken(getToken)
        }
    }, [])


    const editUser = () => {

        fetch("http://api.pishroopardakht.com/api/user/editUser", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`
            },
            body: JSON.stringify({
                id: props.item._id,
                name: name.value,
                email: email.value,
                phone: phone.value,
                codeMelli: codeMeli.value,
                emailActivate: true,
                address: address.value,
                authentication : status,
                score : score.value,
                postalCode: props.item.postalCode,
                status : props.item.status,
                city : props.item.city,
                phoneSabet: phoneSabet.value,

            })
        }).
            then(async (res) => {
                if (res.status < 300) {
                    res = await res.json();
                    Toast({text : res.message})
                    props.onHide(false)

                } else {
                    res = await res.json();
                    Toast({text : res.message , type : "error"})

                }
            }).catch(err => console.log(err))
    }

    return (
        <div className="w-100">
            
            <Row className='d-flex justify-content-start align-items-center my-4 p-2 w-100'>
                
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={name.value && name.value} onChange={name.onChange} title="نام" />
                </Col>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={phone.value} onChange={phone.onChange} title="شماره تماس" />
                </Col>
                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={email.value} onChange={email.onChange} title="ایمیل" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={codeMeli.value} onChange={codeMeli.onChange} title="کد ملی" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={address.value} onChange={address.onChange} title="آدرس" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={score.value} onChange={score.onChange} title="امتیاز" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={credit.value} onChange={credit.onChange} title="اعتبار" />
                </Col>

                <Col xs={12} md={12} sm={12} lg={6} xl={6} >
                    <Input value={phoneSabet.value} onChange={phoneSabet.onChange} title="شماره ثابت" />
                </Col>

                <Col className='d-flex flex-column align-items-start' xs={12} md={12} sm={12} lg={6} xl={6} >
                    <p className='fs-14 font-fa fw-700 mx-2'>وضعیت</p>
                    <Dropdown className='bg-yellow w-100 ' style={{borderRadius : "8px"}}>
                        <Dropdown.Toggle variant='yellow' style={{borderRadius : "8px"}} className='w-100 font-fa fs-15 fw-500 text-white' id="dropdown-basic">
                            انتخاب کنید 
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className='font-fa fw-500 fs-15 text-center'  onClick={()=>{setStatus(0)}} >در انتظار</Dropdown.Item>
                            <Dropdown.Item className='font-fa fw-500 fs-15 text-center' onClick={()=>{setStatus(1)}}>تایید شده</Dropdown.Item>
                            <Dropdown.Item className='font-fa fw-500 fs-15 text-center' onClick={()=>{setStatus(2)}}>رد شده</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

                <Col className='d-flex flex-column align-items-start justify-content-center my-2 ' xs={12} md={12} sm={12} lg={6} xl={6} >
                    <p className='fs-14 font-fa fw-700 mx-2'>ایمیل</p>
                    <Form>
                        <Form.Check
                            inline
                            label="inactive"
                            name="group1"
                            type="radio"
                            id={`inline-radio-1`}
                            checked={!accEmail}
                            value="false"
                            onClick={() => { setAccEmail(false);  }}
                        />
                        <Form.Check
                            inline
                            label="active"
                            name="group1"
                            type="radio"
                            id={`inline-radio-2`}
                            checked={accEmail}
                            value="true"
                            onClick={() => {setAccEmail(true); }}
                        />
                    </Form>
                </Col>

                <Col className='my-3' xs={12} md={12} sm={12} lg={12} xl={12} >
                    <Btn onClick={editUser} title="ذخیره" />
                </Col>
                
            </Row>
        </div>
    )
}

export default UserListModal