import React from "react";

import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
      {
        pr :0,
      title: 'ادمین',
      path: '/admin/Add-admin',
    },
    {   pr :1, 
      title: 'سفارشات خرید',
      path: '/admin/Purchases-made',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
    },
    {   pr :2,
      title: 'سفارشات فروش',
      path: '/admin/Sales-orders',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

    },
    {   pr :3,
      title: 'پرداخت های افزایش اعتبار',
      path: '/admin/Increase-credit',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,

    },
    {   pr :4,
      title: 'کارت های بانکی',
      path: '/admin/Bank-cards',
    },
    {   pr :5,
      title: 'تنظیمات',
      path: '/admin/Setting',
    },
    {   pr :6,
      title: 'رمز ارز',
      path: '/admin/Crypto',
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
  
      subNav: [
        {
          title: 'اضافه کردن',
          path: '/admin/Crypto/add',
        },
        {
          title: 'ویرایش',
          path: '/admin/Crypto/edit',
        }
      ]
    },
    {   pr :7,
        title: 'ولت',
        path: '/admin/Wallet/edit',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
        subNav: [
          {
            title: 'اضافه کردن',
            path: '/admin/Wallet/add',
          },
          {
            title: 'ویرایش',
            path: '/admin/Wallet/edit',
          }
        ]
      },
      {   pr :8,
        title: 'بلاگ',
        path: '/admin/Blog/edit',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
        subNav: [
          {
            title: 'اضافه کردن',
            path: '/admin/Blog/add',
          },
          {
            title: 'ویرایش',
            path: '/admin/Blog/edit',
          }
        ]
      },
      {   pr :9,
        title: 'دپارتمان',
        path: '/admin/Department/edit',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
        subNav: [
          {
            title: 'اضافه کردن',
            path: '/admin/Department/add',
          },
          {
            title: 'ویرایش',
            path: '/admin/Department/edit',
          }
        ]
      },
      {   pr :10,
        title: 'حساب',
        path: '/admin/Account',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
        subNav: [
          {
            title: 'اضافه کردن',
            path: '/admin/Account/add',
          },
          {
            title: 'ویرایش',
            path: '/admin/Account/edit',
          }
        ]
      },
      {   pr :11,
        title: 'پیام',
        path: '/admin/Account',
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    
        subNav: [
          {
            title: 'اضافه کردن',
            path: '/admin/Message/add',
          },
          {
            title: 'ویرایش',
            path: '/admin/Message/edit',
          }
        ]
      },
    {   pr :12,
      title: 'تیکت',
      path: '/admin/Support',
    },
    {   pr :13,
        title: 'احراز هویت',
        path: '/admin/Authentication',
    },
    {   pr :14,
        title: 'مشاهده لیست کاربران',
        path: '/admin/User-list',
    },
  ];