import React, { useState, useEffect } from 'react'

import Axios from 'axios';
import { Row, Col } from "react-bootstrap"
// import Input from '../../../components/settingg/Input/Input'
import Btn from '../../../components/settingg/BTN/Btn'
import useEditInput from '../../../hooks/useEditInput'
import { toast, ToastContainer } from "react-toastify"
import Toast from '../../../methods/_toast';
import { AiOutlineUpload } from 'react-icons/ai'
import PicSpinner from '../../../components/picSpinner/PicSpinner'
import BackDrop from '../../../components/BackDrop/BackDrop';
import axios from '../../../boot/axios';
import Input from '../../../components/Input';
import './AddBlog.scss'

const AddBlog = () => {
  const [image, setImage] = useState(null)
  // const [token, setToken] = useState("")
  // const [getAddress, setAddress] = useState("")
  const [dataa, setData] = useState({})
  const title = useEditInput("")
  const content = useEditInput("")
  // useEffect(() => {

  //   const getToken = JSON.parse(localStorage.getItem('token'));
  //   if (getToken) {
  //     setToken(getToken)
  //   }
  // })

  const addPost = async () => {
    const url = "blog/addPost"
    
    axios.post(url, dataa).then(({ data }) => {
      Toast({text : data.message})
      setData({})
    });

    // try {
    //   const resAPI = await fetch("http://api.pishroopardakht.com/api/blog/addPost", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },

    //     body: JSON.stringify({
    //       title: title.value,
    //       content: content.value,
    //       image : getAddress


    //     })

    //   })

    //   if (resAPI.status < 300) {
    //     const res = await resAPI.json();

    //     toast.success(res.message, {
    //       position: "top-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     title.reset()
    //     content.reset()
    //     setImage(null)

    //   } else {
    //     const res = await resAPI.json();
    //     console.log(res)
    //     toast.error(res.message, {
    //       position: "top-left",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
  }

  let data = new FormData();

  const addImage = (e) => {


    setImage(e.target.files[0])
    // setData((p)=>({...p , image : e.target.files[0]}))


  }

  const uploadImageHandler = () => {
    
    data.append("image", image);
    const url = "blog/uploadpostimage"
    axios.post(url,data).then(({data})=>{

      setData((p)=>({...p , image : data.address}))
      Toast({ text: data.message })

    })
    // Axios(
    //   {
    //     method: "POST",
    //     url: `http://api.pishroopardakht.com/api/blog/uploadpostimage`,
    //     data,
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Authorization": token
    //     }
    //   }

    // )
    //   .then(res => {
    //     if (res.status < 300) {

    //       setShow(false)
    //       setAddress(res.data.address)
    //       toast.success(res.data.message, {
    //         position: "top-left",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       });
    //     } else {
    //       console.log(res)
    //       toast.error(res.message, {
    //         position: "top-left",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       });
    //     }

    //   })
  }


  const formControls = [
   
    {
      label : "title",
      state : "title"
    }
  ]

  const formControls2 = [
    {
      as: "textarea",
      label: "توضیحات",
      state: "content",
    },
    
  ]


  return (
    <div className='w-100 ADD-BLOG '>

      
      <Row className='w-100 d-flex justify-content-center align-items-center'>
        <Col className="my-4" xs={12} sm={12} md={12} lg={6} xl={6} >
          <div className=' w-100 p-2 addBLOG-div shadow mx-1 my-1'>
            <div className='bg-grayy d-flex justify-content-center align-items-center divTITLE'>
              <p className='text-center fw-700 fs-12 font-fa text-purple my-1'>اضافه کردن  بلاگ</p>
            </div>
            <Row className="mx-1 my-5 d-flex align-items-center">
             
                {/* <Input className="my-1" title="title" value={title.value} onChange={title.onChange} /> */}
                {formControls.map((e) => (
                                <Col
                                    key={e.state}
                                    xs="12"
                                    md={12}
                                    lg={12}
                                    className=""
                                >
                                    {React.createElement(e.tag ?? Input, {
                                        ...e,
                                        value: dataa[e.state],
                                        setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                                    })}
                                </Col>
                ))}
              
              <Col xs={12} sm={12} md={12} lg={12} xl={12}  >
                <p className="fs-14 m-0 mb-1">آپلود عکس</p>
                <div className="d-flex justify-content-center ">
                  <input onChange={(e) => setImage(e.target.files[0])} type="file" id="uploadImage" for="uploadImage" hidden />
                  <label style={{ padding: "7px", borderRadius: "8px", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", minWidth: "100px " }} className='w-75  bg-yellow  text-white text-center font-fa fw-700 fs-12 cursor-pointer  ' for="uploadImage"> انتخاب عکس </label>
                  <button onClick={uploadImageHandler} style={{ padding: "6px", borderRadius: "8px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", minWidth: "70px" }} className='w-25  bg-light  text-yellow border-0 text-center font-fa fw-700 fs-12 cursor-pointer '>آپلود <AiOutlineUpload /></button>
                </div>
                
              </Col>
              
              {formControls2.map((e) => (
                                <Col
                                    key={e.state}
                                    xs="12"
                                    md={e.state === "content" ? "12" : "12"}
                                    lg={e.state === "content" ? "12" : "12"}
                                >
                                    {React.createElement(e.tag ?? Input, {
                                        ...e,
                                        value: dataa[e.state],
                                        // onChange : (a) => {setData((p) => ({...p, [e.state] : a.target.value }))},
                                        setValue: (val) => setData((p) => ({ ...p, [e.state]: val }))
                                    })}
                                </Col>
                ))}
              


              <Col className="d-flex justify-content-center my-2" xs={12} sm={12} md={12} lg={12} xl={12} >
                <Btn disabled={!dataa.content || !dataa.title} onClick={addPost} title="ثبت اطلاعات بلاگ " />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AddBlog